import { joiResolver } from '@hookform/resolvers/joi';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledSelect,
  IconButton,
  InnerContentContainer,
  Input,
  Link,
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  ResponsiveBox,
  Snackbar,
  SolidIconButton,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  Tooltip,
  accountRoles,
  flattenFieldErrorsObject,
  getAvatarVariantFromString,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { LEARN_MORE_ABOUT_TEAM_ROLES_URL } from '../../../../constants';
import { useInviteTeamMembers } from '../../../../helpers';
import {
  PendingDepartmentInvitation,
  UpdateUserForm,
  UpdateUserValidation,
  User,
} from '../../../../models';
import { Logger } from '../../../../services';
import {
  AccountSelector,
  DeleteInvitationSelector,
  PendingInvitationsSelector,
  RemoveUserSelector,
  UpdateUserSelector,
  UsersSelector,
  deleteInvitationThunk,
  getPendingInvitationsThunk,
  getUsersThunk,
  removeUserThunk,
  updateUserThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { getTeamRoleTx } from '../../../../utils';

function getUserFullname(user: User) {
  return `${user.firstName} ${user.lastName}`;
}

const TEAM_MEMBER_ROLE_OPTIONS = accountRoles.map((accountRole) => ({
  value: accountRole,
  tx: getTeamRoleTx(accountRole),
}));

const AddTeamMemberContext = React.createContext<{
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  isOpen: false,
  setIsOpen: () => {},
});

function AddTeamMemberProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const location = useLocation();

  const [isOpen, setIsOpen] = React.useState(location.search.includes('modal'));

  return (
    <AddTeamMemberContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </AddTeamMemberContext.Provider>
  );
}

const RemoveTeamMemberContext = React.createContext<
  ReturnType<typeof useConfirm>
>({
  closeConfirm: () => {},
  confirmValue: null,
  isOpen: false,
  openConfirm: () => {},
});

function RemoveTeamMemberProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const confirm = useConfirm();

  return (
    <RemoveTeamMemberContext.Provider value={confirm}>
      {children}
    </RemoveTeamMemberContext.Provider>
  );
}

const TeamMembersContext = React.createContext<{
  selectedUser: User | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>;
}>({
  selectedUser: null,
  setSelectedUser: () => {},
});

function TeamMembersProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  return (
    <TeamMembersContext.Provider value={{ selectedUser, setSelectedUser }}>
      {children}
    </TeamMembersContext.Provider>
  );
}

const TABLE_COLUMN_WIDTHS = {
  fullName: 200,
  email: 200,
  role: 200,
  actions: 40,
};

function TeamMembersTable() {
  const { setSelectedUser, selectedUser } =
    React.useContext(TeamMembersContext);
  const users = useSelector(UsersSelector.selectAll);
  const usersStatus = useSelector(UsersSelector.selectStatus);
  const pendingInvitations = useSelector(PendingInvitationsSelector.selectAll);
  const deleteInvitationStatus = useSelector(
    DeleteInvitationSelector.selectStatus,
  );
  const dispatch = useDispatch();

  const { confirmValue, closeConfirm, isOpen, openConfirm } =
    useConfirm<string>();

  const renderTeamMemberTableRow = (user: User) => {
    const openTeamMemberSidebar = () => setSelectedUser(user);

    const fullName = getUserFullname(user);

    return (
      <TableRow
        highlight={user.userKey === selectedUser?.userKey}
        onClick={openTeamMemberSidebar}
        key={user.userKey}
      >
        <TableCell width={TABLE_COLUMN_WIDTHS.fullName}>
          <Avatar
            variant={getAvatarVariantFromString(user.userKey)}
            fallbackLetter={fullName.charAt(0)}
            minWidth={40}
            src={user.profilePicture?.thumbnail64.url ?? undefined}
          />
          <Text ml={8} variant="bodySm" text={fullName} as="span" />
        </TableCell>

        <TableCell width={TABLE_COLUMN_WIDTHS.email} text={user.email} />
        <TableCell width={TABLE_COLUMN_WIDTHS.role}>
          <Chip
            variant={user.role === 'admin' ? 'primary' : 'secondary'}
            tx={getTeamRoleTx(user.role)}
          />
        </TableCell>

        <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
      </TableRow>
    );
  };

  if (usersStatus === 'pending' && users.length === 0) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.team.table.team-members.full-name" />
            <TableHead tx="label.team.table.team-members.email" />
            <TableHead tx="label.team.table.team-members.role" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  const removeInvitation = async () => {
    if (!confirmValue) return;

    await dispatch(deleteInvitationThunk(confirmValue));

    closeConfirm();
  };

  const renderTeamInvitationTableRow = (
    invitation: PendingDepartmentInvitation,
  ) => {
    const stageInvitationForRemoval = () => {
      openConfirm(invitation.departmentInvitationKey);
    };

    return (
      <TableRow key={invitation.departmentInvitationKey}>
        <TableCell width={200} text="" />
        <TableCell width={200} text={invitation.email} />
        <TableCell width={200}>
          <Status
            tx="label.team.table.team-members.pending-invitation"
            variant="warning"
          />
        </TableCell>
        <TableCell width={40} hoverCell fixedRight>
          <IconButton
            ml="auto"
            icon="trash-outline"
            onClick={stageInvitationForRemoval}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Confirm
        cancelTx="prompt.pending-invitation.cancel"
        confirmTx="prompt.pending-invitation.confirm"
        isLoading={deleteInvitationStatus === 'pending'}
        isOpen={isOpen}
        messageTx="prompt.pending-invitation.message"
        onCancel={closeConfirm}
        onConfirm={removeInvitation}
        titleTx="prompt.pending-invitation.title"
      />

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.team.table.team-members.full-name" />
            <TableHead tx="label.team.table.team-members.email" />
            <TableHead tx="label.team.table.team-members.role" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody>
          {users.map(renderTeamMemberTableRow)}
          {pendingInvitations.map(renderTeamInvitationTableRow)}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

function OpenAddTeamMemberModalButton() {
  const { setIsOpen } = React.useContext(AddTeamMemberContext);

  const totalMembersCount = useSelector(UsersSelector.selectCount);
  const isAdmin = useSelector(AccountSelector.selectIsAdmin);

  if (totalMembersCount === 0) {
    return null;
  }

  if (!isAdmin) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.team.invite-team-member.only-department-admin-invite"
      >
        <ResponsiveBox
          xs={
            <Button
              disabled
              tx="label.team.invite-team-member.open-modal"
              variant="primary"
            />
          }
        >
          <Tooltip
            placement="left"
            titleTx="label.team.invite-team-member.only-department-admin-invite"
          >
            <SolidIconButton icon="plus-circle-outline" disabled />
          </Tooltip>
        </ResponsiveBox>
      </Tooltip>
    );
  }

  const openAddTeamMemberModal = () => setIsOpen(true);

  return (
    <ResponsiveBox
      xs={
        <Button
          onClick={openAddTeamMemberModal}
          tx="label.team.invite-team-member.open-modal"
          variant="primary"
        />
      }
    >
      <Tooltip
        placement="left"
        titleTx="label.team.invite-team-member.open-modal"
      >
        <SolidIconButton
          icon="plus-circle-outline"
          onClick={openAddTeamMemberModal}
        />
      </Tooltip>
    </ResponsiveBox>
  );
}

function TeamMembersContent() {
  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          color="pageTitle"
          variant="titleMd"
          tx="title.team.dashboard"
          as="h1"
        />

        <OpenAddTeamMemberModalButton />
      </Box>

      <Box flex flexDirection="column" gap={16}>
        <TeamMembersTable />
      </Box>
    </React.Fragment>
  );
}

function RemoveTeamMemberButton(props: { openConfirm: () => void }) {
  const { openConfirm } = props;

  const { selectedUser } = React.useContext(TeamMembersContext);

  const userKey = useSelector(AccountSelector.selectUserKey);
  const isAdmin = useSelector(AccountSelector.selectIsAdmin);

  if (selectedUser?.userKey === userKey) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.team.invite-team-member.cannot-remove-self"
      >
        <Button disabled tx="button.revoke" variant="destructive" />
      </Tooltip>
    );
  }

  if (!isAdmin) {
    return (
      <Tooltip
        placement="left"
        titleTx="label.team.invite-team-member.only-department-admin-remove"
      >
        <Button disabled tx="button.revoke" variant="destructive" />
      </Tooltip>
    );
  }

  return (
    <Button tx="button.revoke" variant="destructive" onClick={openConfirm} />
  );
}

function UpdateTeamMemberFormContent() {
  const { selectedUser } = React.useContext(TeamMembersContext);

  const dispatch = useDispatch();

  const isAdmin = useSelector(AccountSelector.selectIsAdmin);
  const userKey = useSelector(AccountSelector.selectUserKey);

  const updateTeamMemberStatus = useSelector(UpdateUserSelector.selectStatus);

  const { openConfirm } = React.useContext(RemoveTeamMemberContext);

  const isLoading = updateTeamMemberStatus === 'pending';

  const formMethods = useForm<UpdateUserForm>({
    resolver: joiResolver(UpdateUserValidation),
    defaultValues: {
      role: selectedUser?.role,
    },
  });

  const updateTeamMember = formMethods.handleSubmit(
    (data) => {
      if (!selectedUser) return;

      dispatch(
        updateUserThunk({
          role: data.role,
          userKey: selectedUser.userKey,
          fullName: `${selectedUser.firstName} ${selectedUser.lastName}`,
        }),
      );
    },
    (err) => {
      Logger.warning('updateTeamMember Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      role: selectedUser?.role,
    });
  }, [formMethods, selectedUser?.email, selectedUser?.role]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <Box flexAlign="center" flex flexJustify="between">
          <Text
            as="h1"
            tx="label.team.edit-team-member.title"
            variant="bodyLgBold"
          />

          <Button
            disabled={!isAdmin}
            isLoading={isLoading}
            onClick={updateTeamMember}
            tx="button.save"
            variant="primary"
          />
        </Box>

        <Box flex flexDirection="column" gap={32}>
          <Input
            disabled
            labelTx="label.team.table.team-members.full-name"
            readOnly
            value={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
          />

          <Input
            disabled
            labelTx="label.team.email"
            name="email"
            readOnly
            value={selectedUser?.email ?? ''}
          />

          <ControlledSelect
            name="role"
            width="100%"
            labelTx="label.team.role"
            options={TEAM_MEMBER_ROLE_OPTIONS}
            disabled={
              isLoading || !isAdmin || selectedUser?.userKey === userKey
            }
          />
        </Box>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer gap={16} flexWrap>
        <RemoveTeamMemberButton openConfirm={openConfirm} />

        <Link
          tx="link.general.learn-more-about-team-roles"
          href={LEARN_MORE_ABOUT_TEAM_ROLES_URL}
          variant="secondary"
          target="_blank"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}

function RemoveTeamMemberConfirm() {
  const removeUserStatus = useSelector(RemoveUserSelector.selectStatus);

  const dispatch = useDispatch();

  const { setSelectedUser, selectedUser } =
    React.useContext(TeamMembersContext);

  const { closeConfirm, isOpen } = React.useContext(RemoveTeamMemberContext);

  const closeTeamMembersSidebar = () => setSelectedUser(null);

  const removeTeamMember = async () => {
    if (!selectedUser) return;

    const res = await dispatch(
      removeUserThunk({
        userKey: selectedUser.userKey,
        fullName: `${selectedUser.firstName} ${selectedUser.lastName}`,
      }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      closeConfirm();
      closeTeamMembersSidebar();
    }
  };

  return (
    <Confirm
      cancelTx="button.cancel"
      confirmTx="prompt.remove-team-member.confirm"
      confirmTxArgs={{
        fullName: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
      }}
      isLoading={removeUserStatus === 'pending'}
      isOpen={isOpen}
      messageTx="prompt.remove-team-member.message"
      messageTxArgs={{
        fullName: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
      }}
      onCancel={closeConfirm}
      onConfirm={removeTeamMember}
      titleTx="prompt.remove-team-member.title"
    />
  );
}

function TeamMembersSidebar() {
  const { setSelectedUser, selectedUser } =
    React.useContext(TeamMembersContext);

  const isOpen = selectedUser !== null;
  const closeTeamMembersSidebar = () => setSelectedUser(null);

  return (
    <React.Fragment>
      <ContentSidebar
        isOpen={isOpen}
        onClose={closeTeamMembersSidebar}
        width={460}
      >
        <UpdateTeamMemberFormContent />
      </ContentSidebar>

      <RemoveTeamMemberConfirm />
    </React.Fragment>
  );
}

function AddTeamMemberModal() {
  const { isOpen, setIsOpen } = React.useContext(AddTeamMemberContext);

  const closeModal = () => setIsOpen(false);

  const {
    formMethods,
    invites,
    everyEmailIsEmptyString,
    sendInvites,
    addInvite,
    isLoading,
  } = useInviteTeamMembers({
    onSuccess: closeModal,
  });

  return (
    <React.Fragment>
      <Modal width={800} isOpen={isOpen} onClose={closeModal}>
        <ModalContentContainer>
          <ModalHeaderContainer>
            <ModalTitle
              tx="label.team.invite-team-member.label"
              textAlign="center"
            />
          </ModalHeaderContainer>

          <ModalBodyContainer>
            <Box flex flexDirection="column" gap={32}>
              <FormProvider {...formMethods}>{invites}</FormProvider>

              <Box flex flexJustify="center">
                <Link
                  tx="link.general.learn-more-about-team-roles"
                  href={LEARN_MORE_ABOUT_TEAM_ROLES_URL}
                  variant="secondary"
                  target="_blank"
                />
              </Box>
            </Box>
          </ModalBodyContainer>

          <ModalFooterContainer>
            <Button
              onClick={addInvite}
              variant="secondary"
              tx="button.add-invite"
              disabled={isLoading}
            />
            <Button
              isLoading={isLoading}
              onClick={sendInvites}
              variant="primary"
              tx="button.invite"
            />
          </ModalFooterContainer>
        </ModalContentContainer>
      </Modal>

      {formMethods.formState.isSubmitted &&
        everyEmailIsEmptyString &&
        isOpen && (
          <Snackbar zIndex={10000} placement="top-end">
            <Alert variant="error" subtitleTx="errors.invitations.empty" />
          </Snackbar>
        )}
    </React.Fragment>
  );
}

export function Team() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUsersThunk());
    dispatch(getPendingInvitationsThunk());
  }, [dispatch]);

  return (
    <TeamMembersProvider>
      <AddTeamMemberProvider>
        <RemoveTeamMemberProvider>
          <ContentContainer>
            <InnerContentContainer>
              <TeamMembersContent />
            </InnerContentContainer>
          </ContentContainer>

          <TeamMembersSidebar />

          <AddTeamMemberModal />
        </RemoveTeamMemberProvider>
      </AddTeamMemberProvider>
    </TeamMembersProvider>
  );
}
