import { useCheckFeatureFlag } from '@orbiapp/components';

import { CreatePostModal as V1_CreatePostModal } from './v1';
import { CreatePostModal as V2_CreatePostModal } from './v2';

export function CreatePostModal() {
  const postsImproved = useCheckFeatureFlag('student_dashboard_posts_improved');

  if (!postsImproved) {
    return <V1_CreatePostModal />;
  }

  return <V2_CreatePostModal />;
}
