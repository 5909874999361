import Joi from 'joi';

import { ActivityPostSchema } from './activity-post.schema';

export const CreateActivityPostValidation = Joi.object({
  activityKey: ActivityPostSchema.activityKey,
  audiences: ActivityPostSchema.audiences,
  message: ActivityPostSchema.message,
});

export const UpdateActivityPostValidation = Joi.object({
  activityKey: ActivityPostSchema.activityKey,
  activityPostKey: ActivityPostSchema.activityPostKey,
  message: ActivityPostSchema.message,
});
