import React from 'react';

import { Box } from '../box';
import { Button } from '../button';
import { Link } from '../link';
import { Styled } from './upload.styled';
import {
  UploadError,
  UploadProps,
  UploadRestrictions,
  UploadResult,
} from './upload.types';

async function getUploadResult(file: File): Promise<UploadResult> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (e) => {
      const result = reader.result?.toString();

      if (!result) {
        reject(e);
        return;
      }

      const img = new Image();
      img.src = result;

      img.onload = () => {
        resolve({
          base64: result,
          height: img.height,
          name: file.name,
          size: file.size,
          type: file.type,
          width: img.width,
        });
      };

      img.onerror = reject;
      img.onabort = reject;
    };

    reader.onerror = reject;
    reader.onabort = reject;
  });
}

function useDragDrop(
  ref: React.RefObject<HTMLDivElement>,
  onDrop: (files: File[]) => void,
) {
  const [dragOver, setDragOver] = React.useState(false);

  React.useEffect(() => {
    const uploadContainer = ref.current;

    if (!uploadContainer) return;

    uploadContainer.addEventListener('dragover', (e) => {
      e.preventDefault();
      setDragOver(true);
    });

    uploadContainer.addEventListener('dragleave', (e) => {
      e.preventDefault();
      setDragOver(false);
    });

    uploadContainer.addEventListener('drop', (e) => {
      e.preventDefault();
      setDragOver(false);

      const files = e.dataTransfer?.files;

      if (files) {
        onDrop(Array.from(files));
      }
    });
  }, [ref, setDragOver, onDrop]);

  return dragOver;
}

function getError(
  uploadResult: UploadResult,
  restrictions: UploadRestrictions,
): UploadError | null {
  const {
    minHeight = 0,
    maxSize,
    minWidth = 0,
    maxHeight,
    maxWidth,
    accept,
  } = restrictions;

  if (accept && !accept.includes(uploadResult.type)) {
    return {
      height: uploadResult.height,
      message: 'accept',
      size: uploadResult.size,
      width: uploadResult.width,
      type: uploadResult.type,
    };
  }

  if (maxSize && uploadResult.size > maxSize) {
    return {
      height: uploadResult.height,
      message: 'maxSize',
      size: uploadResult.size,
      width: uploadResult.width,
      type: uploadResult.type,
    };
  }

  if (uploadResult.width < minWidth) {
    return {
      height: uploadResult.height,
      message: 'minWidth',
      size: uploadResult.size,
      width: uploadResult.width,
      type: uploadResult.type,
    };
  }

  if (uploadResult.height < minHeight) {
    return {
      height: uploadResult.height,
      message: 'minHeight',
      size: uploadResult.size,
      width: uploadResult.width,
      type: uploadResult.type,
    };
  }

  if (maxHeight && uploadResult.height > maxHeight) {
    return {
      height: uploadResult.height,
      message: 'maxHeight',
      size: uploadResult.size,
      width: uploadResult.width,
      type: uploadResult.type,
    };
  }

  if (maxWidth && uploadResult.width > maxWidth) {
    return {
      height: uploadResult.height,
      message: 'maxWidth',
      size: uploadResult.size,
      width: uploadResult.width,
      type: uploadResult.type,
    };
  }

  return null;
}

export function Upload(props: UploadProps) {
  const {
    accept,
    aspectRatio,
    buttonText,
    buttonTx,
    buttonTxArgs,
    disabled,
    maxHeight,
    maxSize,
    maxWidth,
    minHeight,
    minWidth,
    title,
    titleTx,
    titleTxArgs,
    linkHref,
    linkTx,
    linkTarget,
    onError,
    onUpload,
    src,
    height = 333,
    width,
  } = props;

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const uploadContainerRef = React.useRef<HTMLDivElement>(null);

  const handleUpload = async (files: File[]) => {
    if (disabled) return;

    const uploadResult = await getUploadResult(files[0]);

    if (!onError) {
      onUpload(uploadResult);
      return;
    }

    const error = getError(uploadResult, {
      accept,
      maxHeight,
      maxSize,
      maxWidth,
      minHeight,
      minWidth,
    });

    if (onError && error) {
      onError(error);
      return;
    }

    onUpload(uploadResult);
  };

  const dragOver = useDragDrop(uploadContainerRef, handleUpload);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleUpload(Array.from(e.target.files));
    }

    e.target.value = '';
  };

  const openFileInput = () => {
    fileInputRef.current?.click();
  };

  const content = (
    <Box flex flexAlign="center" flexDirection="column" gap={4}>
      <Styled.UploadText
        text={title}
        tx={titleTx}
        txArgs={titleTxArgs}
        variant="bodyXs"
      />
      <Styled.UploadText text={`${minWidth} x ${minHeight}`} variant="bodyXs" />

      {linkHref && (
        <Box mt={32}>
          <Link
            tx={linkTx}
            href={linkHref}
            target={linkTarget}
            small
            onClick={(e) => e.stopPropagation()}
            variant={src ? 'quaternary' : 'primary'}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <Styled.Upload
      aria-disabled={disabled}
      aspectRatio={aspectRatio}
      disabled={disabled}
      flex
      flexAlign="center"
      flexDirection="column"
      flexJustify="center"
      gap={16}
      onClick={openFileInput}
      px={16}
      py={32}
      ref={uploadContainerRef}
      relative
      src={dragOver ? '' : src}
      width={width}
      height={height}
    >
      <input
        accept={accept}
        hidden
        onChange={handleFileSelect}
        ref={fileInputRef}
        type="file"
      />

      {!dragOver && (buttonText || buttonTx) && src && (
        <Styled.UploadBackdrop
          absolute
          aspectRatio={aspectRatio}
          flex
          flexAlign="center"
          flexDirection="column"
          flexJustify="center"
          gap={16}
          px={16}
          py={32}
          width="100%"
          height="100%"
        >
          <Button
            isDark
            text={buttonText}
            tx={buttonTx}
            txArgs={buttonTxArgs}
            variant="secondary"
          />

          {content}
        </Styled.UploadBackdrop>
      )}

      {dragOver && <Styled.UploadIcon color="uploadIcon" name="upload" />}

      {!dragOver && !src && (
        <Box gap={16} flex flexDirection="column">
          <span></span>

          {(buttonText || buttonTx) && (
            <Button
              text={buttonText}
              tx={buttonTx}
              txArgs={buttonTxArgs}
              variant="secondary"
              mx="auto"
              disabled={disabled}
            />
          )}

          {content}
        </Box>
      )}
    </Styled.Upload>
  );
}
