import { Modal } from '@orbiapp/components';
import React from 'react';

import { QuickActionsContext } from '../../quick-actions';
import { DepartmentPostForm } from './department-post-form';

export function CreatePostModal() {
  const { createPostModalState } = React.useContext(QuickActionsContext);

  return (
    <Modal
      width={850}
      isOpen={createPostModalState.isOpen}
      onClose={createPostModalState.closeModal}
    >
      <DepartmentPostForm />
    </Modal>
  );
}
