import styled, { css } from 'styled-components';

import { Box } from '../../components';

const ContentSidebar = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen, theme } = props;

    return css`
      transition: width ${theme.transitions.fast};

      ${isOpen &&
      css`
        border-left: 1px solid ${theme.colors.contentSidebarBorder};
      `}
    `;
  }}
`;

const CloseSidebarButtonBox = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen, theme } = props;

    return css`
      transition: ${theme.transitions.fast};
      opacity: 0;
      pointer-events: none;

      ${isOpen &&
      css`
        opacity: 1;
        pointer-events: all;
      `}
    `;
  }}
`;

const ContentSidebarContent = styled(Box)<{ isOpen: boolean }>`
  ${(props) => {
    const { isOpen } = props;

    return css`
      filter: blur(${isOpen ? 0 : 10}px);
    `;
  }}
`;

export const Styled = {
  ContentSidebar,
  CloseSidebarButtonBox,
  ContentSidebarContent,
};
