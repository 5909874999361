import { Analytics } from '../../services';
import {
  createActivityPostThunk,
  createDepartmentPostThunk,
  getActivityPostsThunk,
  getDepartmentPostsThunk,
  getPostsThunk,
  updateActivityPostThunk,
  updateDepartmentPostThunk,
} from './posts.actions';
import {
  activityPostsAdapter,
  departmentPostsAdapter,
  postsAdapter,
} from './posts.adapter';
import { PostsBuilder } from './posts.types';

const getPostsBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(getPostsThunk.fulfilled, (state, action) => {
      state.posts.status = 'idle';

      postsAdapter.setMany(state.posts.data, action.payload);
    })
    .addCase(getPostsThunk.pending, (state, action) => {
      state.posts.status = 'pending';
      state.posts.pagination = action.meta.arg;
      state.posts.error = null;
    })
    .addCase(getPostsThunk.rejected, (state, action) => {
      state.posts.status = 'idle';
      state.posts.error = action.error;
    });
};

const getActivityPostsBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(getActivityPostsThunk.fulfilled, (state, action) => {
      activityPostsAdapter.setMany(state.activityPosts.data, action.payload);
      state.activityPosts.status = 'idle';
    })
    .addCase(getActivityPostsThunk.pending, (state, action) => {
      state.activityPosts.error = null;
      state.activityPosts.pagination = action.meta.arg;
      state.activityPosts.status = 'pending';
    })
    .addCase(getActivityPostsThunk.rejected, (state, action) => {
      state.activityPosts.error = action.error;
      state.activityPosts.status = 'idle';
    });
};

const createActivityPostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(createActivityPostThunk.fulfilled, (state, action) => {
      Analytics.track('create_event_post');

      const createdAt = Date.now();

      activityPostsAdapter.addOne(state.activityPosts.data, {
        activityPostKey: action.payload.activityPostKey,
        message: action.meta.arg.message,
        createdAt,
      });

      postsAdapter.addOne(state.posts.data, {
        postKey: action.payload.activityPostKey,
        activityTitle: action.payload.activityTitle,
        activityKey: action.meta.arg.activityKey,
        message: action.meta.arg.message,
        createdAt,
        type: 'activity',
      });

      state.createActivityPost.data = action.payload.activityPostKey;
      state.createActivityPost.status = 'idle';
    })
    .addCase(createActivityPostThunk.pending, (state) => {
      state.createActivityPost.status = 'pending';
      state.createActivityPost.error = null;
    })
    .addCase(createActivityPostThunk.rejected, (state, action) => {
      state.createActivityPost.status = 'idle';
      state.createActivityPost.error = action.error;
    });
};

const updateActivityPostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(updateActivityPostThunk.fulfilled, (state, action) => {
      activityPostsAdapter.updateOne(state.activityPosts.data, {
        id: action.meta.arg.activityPostKey,
        changes: { message: action.meta.arg.message },
      });

      postsAdapter.updateOne(state.posts.data, {
        id: action.meta.arg.activityPostKey,
        changes: { message: action.meta.arg.message },
      });

      state.updateActivityPost.data = action.meta.arg.activityPostKey;
      state.updateActivityPost.status = 'idle';
    })
    .addCase(updateActivityPostThunk.pending, (state) => {
      state.updateActivityPost.status = 'pending';
      state.updateActivityPost.error = null;
    })
    .addCase(updateActivityPostThunk.rejected, (state, action) => {
      state.updateActivityPost.status = 'idle';
      state.updateActivityPost.error = action.error;
    });
};

const getDepartmentPostsBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(getDepartmentPostsThunk.fulfilled, (state, action) => {
      departmentPostsAdapter.addMany(
        state.departmentPosts.data,
        action.payload,
      );

      state.departmentPosts.status = 'idle';
    })
    .addCase(getDepartmentPostsThunk.pending, (state, action) => {
      state.departmentPosts.error = null;
      state.departmentPosts.pagination = action.meta.arg;
      state.departmentPosts.status = 'pending';
    })
    .addCase(getDepartmentPostsThunk.rejected, (state, action) => {
      state.departmentPosts.error = action.error;
      state.departmentPosts.status = 'idle';
    });
};

const createDepartmentPostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(createDepartmentPostThunk.fulfilled, (state, action) => {
      Analytics.track('create_department_post');

      const createdAt = Date.now();

      departmentPostsAdapter.addOne(state.departmentPosts.data, {
        departmentPostKey: action.payload,
        message: action.meta.arg.message,
        createdAt,
      });

      postsAdapter.addOne(state.posts.data, {
        postKey: action.payload,
        message: action.meta.arg.message,
        activityKey: null,
        activityTitle: null,
        createdAt,
        type: 'department',
      });

      state.createDepartmentPost.data = action.payload;
      state.createDepartmentPost.status = 'idle';
    })
    .addCase(createDepartmentPostThunk.pending, (state) => {
      state.createDepartmentPost.status = 'pending';
      state.createDepartmentPost.error = null;
    })
    .addCase(createDepartmentPostThunk.rejected, (state, action) => {
      state.createDepartmentPost.status = 'idle';
      state.createDepartmentPost.error = action.error;
    });
};

const updateDepartmentPostBuild = (builder: PostsBuilder) => {
  return builder
    .addCase(updateDepartmentPostThunk.fulfilled, (state, action) => {
      departmentPostsAdapter.updateOne(state.departmentPosts.data, {
        id: action.meta.arg.departmentPostKey,
        changes: { message: action.meta.arg.message },
      });

      postsAdapter.updateOne(state.posts.data, {
        id: action.meta.arg.departmentPostKey,
        changes: { message: action.meta.arg.message },
      });

      state.updateDepartmentPost.data = action.meta.arg.departmentPostKey;
      state.updateDepartmentPost.status = 'idle';
    })
    .addCase(updateDepartmentPostThunk.pending, (state) => {
      state.updateDepartmentPost.status = 'pending';
      state.updateDepartmentPost.error = null;
    })
    .addCase(updateDepartmentPostThunk.rejected, (state, action) => {
      state.updateDepartmentPost.status = 'idle';
      state.updateDepartmentPost.error = action.error;
    });
};

export const extraReducers = (builder: PostsBuilder) => {
  getPostsBuild(builder);

  getActivityPostsBuild(builder);
  createActivityPostBuild(builder);
  updateActivityPostBuild(builder);

  getDepartmentPostsBuild(builder);
  createDepartmentPostBuild(builder);
  updateDepartmentPostBuild(builder);
};
