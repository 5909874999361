import {
  ActivityPost,
  CreateActivityPost,
  UpdateActivityPost,
} from '../../../../../../models';
import { CreatePostDto, PostDto, UpdatePostDto } from './posts.dto';

export const activityPostMapper = (postDto: PostDto): ActivityPost => ({
  activityPostKey: postDto.postKey,
  createdAt: postDto.createdAt,
  message: postDto.message,
});

export const createActivityPostMapper = (
  createActivityPost: CreateActivityPost,
): [string, CreatePostDto] => [
  createActivityPost.activityKey,
  {
    audiences: createActivityPost.audiences?.length
      ? createActivityPost.audiences
      : null,
    message: createActivityPost.message,
  },
];

export const updateActivityPostMapper = (
  updateActivityPost: UpdateActivityPost,
): [string, string, UpdatePostDto] => [
  updateActivityPost.activityKey,
  updateActivityPost.activityPostKey,
  { message: updateActivityPost.message },
];
