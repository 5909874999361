import {
  Avatar,
  Box,
  Chip,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  EmptyState,
  Icon,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  LeadingInputBox,
  List,
  ListItem,
  SearchInput,
  Spinner,
  Status,
  TabButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  TrailingInputBox,
  formatDate,
  getAvatarVariantFromString,
  paginatorOptions,
  translate,
  useConfirm,
  useDebounce,
} from '@orbiapp/components';
import React from 'react';

import { useDataGridPagination } from '../../../../../helpers';
import {
  MembershipApplicationAnswer,
  MembershipMemberListItem,
  MembershipMemberListItemPeriod,
  RequestedInfoType,
  membershipMembersSortableKeys,
} from '../../../../../models';
import {
  MembershipMemberSelector,
  MembershipMembersSelector,
  MembershipTypeSelector,
  MembershipTypeStatsSelector,
  RefundMembershipPurchaseSelector,
  getMembershipMemberThunk,
  getMembershipMembersThunk,
  membershipsActions,
  refundMembershipPurchaseThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import {
  formatMembershipId,
  hasValidMembershipPeriod,
  isActivePeriod,
} from '../../../../../utils';
import {
  InviteMembersButton,
  InviteMembersContext,
} from '../../components/invite-members-modal';
import { DownloadMembershipMembers } from './download-users';

function MembersEmptyState() {
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  const { openModal } = React.useContext(InviteMembersContext);

  if (isIntegrated) {
    return (
      <EmptyState titleTx="label.memberships.view-membership.members.empty-state.label" />
    );
  }

  return (
    <EmptyState
      buttonOnClick={openModal}
      buttonTx="label.memberships.invite-members.open-modal-button"
      titleTx="label.memberships.view-membership.members.empty-state.label"
    />
  );
}

function SearchMembers() {
  const search = useSelector(MembershipMembersSelector.selectSearch);
  const pagination = useSelector(MembershipMembersSelector.selectPagination);
  const totalMembersCount = useSelector(
    MembershipTypeStatsSelector.selectTotalMembersCount,
  );

  const dispatch = useDispatch();

  const debounce = useDebounce();

  if (!totalMembersCount) {
    return null;
  }

  const handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(membershipsActions.setMembershipMembersSearch(e.target.value));

    debounce(() => {
      if (!e.target.value) {
        dispatch(membershipsActions.clearMembershipMembers());
        dispatch(membershipsActions.setMembershipMembersSearch(''));
      }

      dispatch(getMembershipMembersThunk(pagination));
    });
  };

  const cancelSearch = () => {
    dispatch(membershipsActions.clearMembershipMembers());
    dispatch(membershipsActions.setMembershipMembersSearch(''));

    dispatch(getMembershipMembersThunk(pagination));
  };

  return (
    <Box width={300}>
      <SearchInput
        onChange={handleSearch}
        value={search}
        placeholderTx="placeholder.search-name-or-email"
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            {search.length > 0 && (
              <IconButton icon="x-mark" onClick={cancelSearch} />
            )}
          </TrailingInputBox>
        }
      />
    </Box>
  );
}

const TABLE_COLUMN_WIDTHS = {
  fullName: 200,
  email: 200,
  status: 200,
  actions: 50,
};

function MembersContent() {
  const membersPagination = useSelector(
    MembershipMembersSelector.selectPagination,
  );
  const membershipMembers = useSelector(MembershipMembersSelector.selectAll);
  const membersStatus = useSelector(MembershipMembersSelector.selectStatus);

  const isLoading = membersStatus === 'pending';

  const dispatch = useDispatch();

  const selectedUserKey = useSelector(MembershipMemberSelector.selectUserKey);

  const { rows, paginatorProps, onPageSizeChange, onPaginate, onSort } =
    useDataGridPagination({
      data: membershipMembers,
      pagination: membersPagination,
      reset: membershipsActions.clearMembershipMembers,
      thunk: getMembershipMembersThunk,
    });

  const renderMembershipMemberTableRow = (
    memberListItem: MembershipMemberListItem,
  ) => {
    const highlight = memberListItem.userKey === selectedUserKey;

    const selectRow = () => {
      if (highlight) return;

      dispatch(getMembershipMemberThunk(memberListItem.userKey));
    };

    const hasActiveMembershipPeriod = hasValidMembershipPeriod(
      memberListItem.periods,
    );

    return (
      <TableRow
        highlight={highlight}
        onClick={selectRow}
        key={memberListItem.userKey}
      >
        <TableCell width={TABLE_COLUMN_WIDTHS.fullName}>
          <Avatar
            fallbackLetter={memberListItem.fullName.charAt(0)}
            minWidth={40}
            src={memberListItem.profilePicture?.thumbnail64.url ?? undefined}
            variant={getAvatarVariantFromString(memberListItem.userKey)}
          />
          <Text
            ml={8}
            variant="bodySm"
            text={memberListItem.fullName ?? ''}
            as="span"
          />
        </TableCell>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.email}
          text={memberListItem.email}
        />
        <TableCell width={TABLE_COLUMN_WIDTHS.status}>
          <Status
            variant={hasActiveMembershipPeriod ? 'success' : 'error'}
            tx={
              hasActiveMembershipPeriod
                ? 'label.memberships.view-membership.members.periods.active'
                : 'label.memberships.view-membership.members.periods.inactive'
            }
          />
        </TableCell>
        <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
      </TableRow>
    );
  };

  if (!membershipMembers.length && isLoading) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.view-membership.members-list.full-name" />
            <TableHead tx="label.memberships.view-membership.members-list.email" />
            <TableHead tx="label.memberships.view-membership.members-list.status" />

            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (!membershipMembers.length) {
    return <MembersEmptyState />;
  }

  return (
    <React.Fragment>
      <Table>
        <TableHeader
          onSort={onSort}
          orderBy={membersPagination.orderBy}
          sortableColumns={Object.values(membershipMembersSortableKeys)}
          sortOrder={membersPagination.sortOrder}
        >
          <TableRow>
            <TableHead
              tx="label.memberships.view-membership.members-list.full-name"
              name={membershipMembersSortableKeys.fullName}
            />
            <TableHead
              tx="label.memberships.view-membership.members-list.email"
              name={membershipMembersSortableKeys.email}
            />
            <TableHead tx="label.memberships.view-membership.members-list.status" />

            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>{rows.map(renderMembershipMemberTableRow)}</TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

function SegmentationPill() {
  const segmentation = useSelector(MembershipMemberSelector.selectSegmentation);
  const semester = useSelector(MembershipMemberSelector.selectSemester);

  if (typeof semester !== 'number') {
    return null;
  }

  if (segmentation === 'semester') {
    return (
      <Chip
        tx="label.memberships.view-membership.semester"
        txArgs={{ semester: semester.toString() }}
        variant="secondary"
      />
    );
  }

  return (
    <Chip
      tx="label.memberships.view-membership.year"
      txArgs={{ year: (Math.floor(semester / 2) + 1).toString() }}
      variant="secondary"
    />
  );
}

function renderRequestedInfoAnswer(answer: MembershipApplicationAnswer) {
  switch (answer.type) {
    case RequestedInfoType.FreeText:
      return (
        <ListItem
          key={answer.key}
          title={answer.question}
          subtitle={answer.answer}
        />
      );

    case RequestedInfoType.MultiChoice:
      return (
        <ListItem
          key={answer.key}
          title={answer.question}
          subtitle={answer.label}
        />
      );

    case RequestedInfoType.Checkbox:
      return (
        <ListItem
          key={answer.key}
          title={answer.question}
          subtitleTx={
            answer.isChecked ? 'label.boolean.yes' : 'label.boolean.no'
          }
        />
      );
  }
}

function SidebarMemberGeneralInfo() {
  const answers = useSelector(MembershipMemberSelector.selectAnswers);

  if (!answers) {
    return null;
  }

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        tx="label.memberships.view-membership.members.membership-application.label"
        variant="bodyMdBold"
      />

      <List>{answers.map(renderRequestedInfoAnswer)}</List>
    </Box>
  );
}

function SidebarMemberPeriods() {
  const periods = useSelector(MembershipMemberSelector.selectPeriods);

  const refundMembershipPurchaseStatus = useSelector(
    RefundMembershipPurchaseSelector.selectStatus,
  );
  const { confirmValue, closeConfirm, isOpen, openConfirm } =
    useConfirm<string>();

  const dispatch = useDispatch();

  if (!periods) {
    return null;
  }

  const isLoading = refundMembershipPurchaseStatus === 'pending';

  const refundMembershipPurchase = async () => {
    if (!confirmValue) return;

    await dispatch(refundMembershipPurchaseThunk(confirmValue));

    closeConfirm();
  };

  const renderPeriod = (period: MembershipMemberListItemPeriod) => {
    const periodIsActive = isActivePeriod(period);

    const handleRefundClick = () => {
      if (!period.membershipPurchaseKey) return;

      openConfirm(period.membershipPurchaseKey);
    };

    if (!period.validTo) {
      return (
        <ListItem
          statusVariant={periodIsActive ? 'success' : 'error'}
          iconDisabled={isLoading}
          statusTx={
            periodIsActive
              ? 'label.memberships.view-membership.members.periods.active'
              : 'label.memberships.view-membership.members.periods.inactive'
          }
          key={period.key}
          title={
            period.name ||
            translate(
              'label.memberships.view-membership.members.periods.no-period-name',
            )
          }
          subtitleTx="label.memberships.view-membership.members.lifetime"
          icon={
            period.membershipPurchaseKey ? 'receipt-refund-outline' : undefined
          }
          onIconClick={handleRefundClick}
          iconTooltipPlacement="left"
          iconTooltipTx="label.memberships.view-membership.members.periods.refund"
        />
      );
    }

    const validThru = [
      formatDate(period.validFrom, 'DD MMM YYYY'),
      formatDate(period.validTo, 'DD MMM YYYY'),
    ].join(' - ');

    return (
      <ListItem
        icon={
          period.membershipPurchaseKey ? 'receipt-refund-outline' : undefined
        }
        statusVariant={periodIsActive ? 'success' : 'error'}
        iconDisabled={isLoading}
        statusTx={
          periodIsActive
            ? 'label.memberships.view-membership.members.periods.active'
            : 'label.memberships.view-membership.members.periods.inactive'
        }
        key={period.key}
        title={
          period.name ||
          translate(
            'label.memberships.view-membership.members.periods.no-period-name',
          )
        }
        onIconClick={handleRefundClick}
        subtitle={validThru}
        iconTooltipPlacement="left"
        iconTooltipTx="label.memberships.view-membership.members.periods.refund"
      />
    );
  };

  return (
    <React.Fragment>
      <Box flex flexDirection="column" gap={16}>
        <Text
          tx="label.memberships.view-membership.members.periods.label"
          variant="bodyMdBold"
        />

        <List>{periods.map(renderPeriod)}</List>
      </Box>

      <Confirm
        cancelTx="prompt.refund-membership-purchase.cancel"
        confirmTx="prompt.refund-membership-purchase.confirm"
        isLoading={isLoading}
        isOpen={isOpen}
        messageTx="prompt.refund-membership-purchase.message"
        onCancel={closeConfirm}
        onConfirm={refundMembershipPurchase}
        titleTx="prompt.refund-membership-purchase.title"
      />
    </React.Fragment>
  );
}

function MembershipSidebarTabs() {
  const [activeTab, setActiveTab] = React.useState<'general-info' | 'periods'>(
    'general-info',
  );

  const handleTabClick = (tab: 'general-info' | 'periods') => () => {
    setActiveTab(tab);
  };

  return (
    <Box flex flexDirection="column" gap={24}>
      <Box flex gap={16} flexJustify="center">
        <TabButton
          isActive={activeTab === 'general-info'}
          onClick={handleTabClick('general-info')}
          tx="label.memberships.view-membership.members.tab-buttons.general-info"
        />
        <TabButton
          isActive={activeTab === 'periods'}
          onClick={handleTabClick('periods')}
          tx="label.memberships.view-membership.members.tab-buttons.periods"
        />
      </Box>

      {activeTab === 'general-info' && <SidebarMemberGeneralInfo />}
      {activeTab === 'periods' && <SidebarMemberPeriods />}
    </Box>
  );
}

function MembersSidebarContent() {
  const memberEmail = useSelector(MembershipMemberSelector.selectEmail);
  const fullName = useSelector(MembershipMemberSelector.selectFullName);
  const periods = useSelector(MembershipMemberSelector.selectPeriods);
  const answers = useSelector(MembershipMemberSelector.selectAnswers);
  const membershipId = useSelector(MembershipMemberSelector.selectMembershipId);

  const hasAnswersAndPeriods = !!answers && !!periods;

  return (
    <Box flex flexDirection="column" gap={24}>
      <Box flex flexDirection="column" gap={8}>
        <Box flexWrap="wrap" flexJustify="between" flex>
          <Text
            text={fullName ?? translate('placeholder.deleted-user')}
            variant="bodyLgBold"
          />

          {typeof membershipId === 'number' && (
            <Text variant="bodyMd" text={formatMembershipId(membershipId)} />
          )}
        </Box>

        <Text
          variant="bodyMd"
          text={memberEmail ?? translate('placeholder.deleted-user')}
        />

        <SegmentationPill />
      </Box>

      {hasAnswersAndPeriods && <MembershipSidebarTabs />}
      {!hasAnswersAndPeriods && periods && <SidebarMemberPeriods />}
      {!hasAnswersAndPeriods && answers && <SidebarMemberGeneralInfo />}
    </Box>
  );
}

function MembersSidebar() {
  const userKey = useSelector(MembershipMemberSelector.selectUserKey);
  const memberStatus = useSelector(MembershipMemberSelector.selectStatus);

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(membershipsActions.clearMembershipMember());
  };

  const isLoading = memberStatus === 'pending';

  return (
    <ContentSidebar
      isOpen={!!userKey || isLoading}
      onClose={onClose}
      width={470}
    >
      <ContentSidebarContentContainer>
        {isLoading ? (
          <Box flex flexJustify="center">
            <Spinner />
          </Box>
        ) : (
          <MembersSidebarContent />
        )}
      </ContentSidebarContentContainer>
    </ContentSidebar>
  );
}

export function ViewMembershipMembers() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(membershipsActions.clearMembershipMember());
    };
  }, [dispatch]);

  return (
    <InnerPageContainer>
      <ContentContainer>
        <InnerContentContainer>
          <Box flex flexJustify="between" flexWrap="wrap" gap={16}>
            <Text
              color="pageTitle"
              as="h1"
              tx="label.memberships.view-membership.tabs.members"
              variant="titleMd"
            />

            <Box flex flexWrap="wrap" gap={16}>
              <DownloadMembershipMembers />

              <InviteMembersButton />
            </Box>
          </Box>

          <Box flex flexDirection="column" gap={16}>
            <SearchMembers />

            <MembersContent />
          </Box>
        </InnerContentContainer>
      </ContentContainer>

      <MembersSidebar />
    </InnerPageContainer>
  );
}
