import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  Checkbox,
  Confirm,
  ContentContainer,
  ControlledInput,
  ControlledSelect,
  CountryCode,
  EmptyState,
  INPUT_X_PADDING,
  IconButton,
  InnerContentContainer,
  InnerPaperContentContainer,
  InnerPaperContentContainerSection,
  InnerPaperContentContainerSectionsContainer,
  Input,
  InputLabel,
  Link,
  LoadingContainer,
  OptionProps,
  PageContainer,
  PaperContentContainer,
  ResponsiveBox,
  Select,
  SolidIconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  WrapBox,
  countryToCurrency,
  flattenFieldErrorsObject,
  formatDate,
  parseCurrency,
  setEndOfDay,
  setStartOfDay,
} from '@orbiapp/components';
import {
  Elements,
  IbanElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeIbanElementOptions, loadStripe } from '@stripe/stripe-js';
import React from 'react';
import {
  FormProvider,
  useForm,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { useBlocker } from 'react-router-dom';

import { STRIPE_TERMS_URL } from '../../../constants';
import { useNavigateStripeConnectForm } from '../../../helpers';
import {
  BusinessType,
  CreateOrbiPayAccount,
  CreateOrbiPayAccountValidation,
  EMAIL_MAX_LENGTH,
  OrbiPayMember,
  OrbiPayRole,
  PayoutListItem,
  PayoutStatus,
  accountHolderTypes,
  businessTypes,
} from '../../../models';
import { Logger } from '../../../services';
import {
  CreateOrbiPayAccountSelector,
  OrbiPaySettingsSelector,
  PayoutsSelector,
  PublicStripeKeySelector,
  createOrbiPayAccountThunk,
  getPayoutThunk,
  getPayoutsThunk,
  getStripePublicKeyThunk,
  globalUiStateActions,
  orbiPayActions,
  useDispatch,
  useSelector,
} from '../../../store';
import {
  getAccountHolderTypeOptionTx,
  getAccountHolderTypeTx,
  getBusinessTypeTx,
  getCountryCodeTx,
} from '../../../utils';
import { Styled } from './orbi-pay.styled';

const FILTER_PAYOUTS_MIN_DATE = Date.UTC(2022, 5, 15);
const FILTER_PAYOUTS_MAX_DATE = Date.now();

const COUNTRY_OPTIONS: OptionProps[] = Object.values(CountryCode).map(
  (countryCode) => ({
    value: countryCode,
    tx: getCountryCodeTx(countryCode),
  }),
);

const BUSINESS_TYPE_OPTIONS: OptionProps[] = businessTypes.map(
  (businessType) => ({
    value: businessType,
    tx: getBusinessTypeTx(businessType),
  }),
);

const ACCOUNT_HOLDER_TYPE_OPTIONS = accountHolderTypes.map(
  (accountHolderType) => ({
    value: accountHolderType,
    tx: getAccountHolderTypeOptionTx(accountHolderType),
  }),
);

const VAT_PERCENT_OPTIONS: OptionProps[] = [
  { value: '25', text: '25%' },
  { value: '12', text: '12%' },
  { value: '6', text: '6%' },
  { value: '0', text: '0%' },
];

function getPayoutStatusTx(status: PayoutStatus): TxString {
  switch (status) {
    case 'canceled':
      return 'label.orbi-pay.table.payment-statuses.canceled';
    case 'failed':
      return 'label.orbi-pay.table.payment-statuses.failed';
    case 'in_transit':
      return 'label.orbi-pay.table.payment-statuses.in_transit';
    case 'paid':
      return 'label.orbi-pay.table.payment-statuses.paid';
    case 'pending':
      return 'label.orbi-pay.table.payment-statuses.pending';
  }
}

function SelectBusinessType() {
  const { setValue, watch } = useFormContext<CreateOrbiPayAccount>();

  const businessType = watch('businessType');

  const handleBusinessTypeChange: React.ChangeEventHandler<
    HTMLSelectElement
  > = (e) => {
    if (e.target.value === 'non_profit') {
      setValue('vatPercent', '0');
    } else {
      setValue('vatPercent', '25');
    }

    setValue('businessType', e.target.value as BusinessType);
  };

  return (
    <Select
      labelTx="label.join-orbi-pay.general-information.business-type.label"
      onChange={handleBusinessTypeChange}
      options={BUSINESS_TYPE_OPTIONS}
      value={businessType}
    />
  );
}

function AccountHolderNameInput() {
  const { watch } = useFormContext<CreateOrbiPayAccount>();

  const accountHolderType = watch('accountHolderType');

  return (
    <ControlledInput
      labelTx={getAccountHolderTypeTx(accountHolderType)}
      name="accountHolderName"
      required
    />
  );
}

function IBanInput() {
  const [focused, setFocused] = React.useState(false);

  const elementOptionsIban: StripeIbanElementOptions = {
    placeholderCountry: 'SE',
    hideIcon: true,
    supportedCountries: ['SEPA'],
    classes: {
      base: 'stripe-iban-element',
      focus: 'is-active',
    },
  };

  const handleFocus = () => setFocused(true);
  const handleBlur = () => setFocused(false);

  return (
    <Styled.InputBox focused={focused} relative>
      <IbanElement
        onBlur={handleBlur}
        onFocus={handleFocus}
        options={elementOptionsIban}
      />

      <InputLabel
        left={INPUT_X_PADDING}
        tx="label.join-orbi-pay.payout-information.iban.label"
      />
    </Styled.InputBox>
  );
}

function TermsOfServiceCheckbox() {
  const { watch, setValue } = useFormContext<CreateOrbiPayAccount>();

  const acceptedTermsOfService = watch('acceptedTermsOfService');

  const toggleAcceptedTermsOfService = () => {
    setValue('acceptedTermsOfService', !acceptedTermsOfService);
  };

  return (
    <Box width="fit-content" flex gap={8} flexAlign="center">
      <Checkbox
        onChange={toggleAcceptedTermsOfService}
        checked={acceptedTermsOfService}
      />

      <Link
        href={STRIPE_TERMS_URL}
        target="_blank"
        tx="label.join-orbi-pay.terms-of-service.label"
      />
    </Box>
  );
}

function JoinOrbiPayNavBlocker() {
  const joinOrbiPayFormState = useFormState();

  const blocker = useBlocker(joinOrbiPayFormState.isDirty);

  return (
    <Confirm
      isOpen={blocker.state === 'blocked'}
      onCancel={blocker.reset || (() => {})}
      onConfirm={blocker.proceed || (() => {})}
      cancelTx="prompt.unsaved-changes.cancel"
      confirmTx="prompt.unsaved-changes.confirm"
      titleTx="prompt.unsaved-changes.title"
      messageTx="prompt.unsaved-changes.message"
    />
  );
}

function JoinOrbiPayButton() {
  const { handleSubmit, watch } = useFormContext<CreateOrbiPayAccount>();

  const navigateStripeConnectForm = useNavigateStripeConnectForm();
  const createOrbiPayAccountStatus = useSelector(
    CreateOrbiPayAccountSelector.selectStatus,
  );

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();

  const acceptedTermsOfService = watch('acceptedTermsOfService');

  const onSubmit = handleSubmit(
    async (data) => {
      const element = elements?.getElement('iban');

      if (!element || !stripe) {
        dispatch(
          globalUiStateActions.setAlert({ alertType: 'element-not-found' }),
        );
        return;
      }

      const { token, error } = await stripe.createToken(element, {
        currency: countryToCurrency(data.countryCode),
        account_holder_name: data.accountHolderName,
        account_holder_type: data.accountHolderType,
      });

      if (error || !token) {
        dispatch(globalUiStateActions.setAlert({ alertType: 'token-error' }));
        return;
      }

      const res = await dispatch(
        createOrbiPayAccountThunk({
          ...data,
          bankAccountTokenId: token.id,
          vatPercent: Number(data.vatPercent),
        }),
      );

      if (res.meta.requestStatus !== 'fulfilled') return;

      await navigateStripeConnectForm({ type: 'account_onboarding' });
    },
    (err) => {
      Logger.warning('joinOrbiPay Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <Button
      disabled={!acceptedTermsOfService}
      isLoading={createOrbiPayAccountStatus === 'pending'}
      onClick={onSubmit}
      tx="button.join-orbi-pay.join"
      variant="primary"
    />
  );
}

function JoinOrbiPayForm() {
  const formMethods = useForm<CreateOrbiPayAccount>({
    defaultValues: {
      email: '',
      vatPercent: '0',
      accountHolderName: '',
      businessType: 'company',
      countryCode: CountryCode.SE,
      accountHolderType: 'company',
      acceptedTermsOfService: false,
    },
    resolver: joiResolver(CreateOrbiPayAccountValidation),
  });

  return (
    <FormProvider {...formMethods}>
      <JoinOrbiPayNavBlocker />

      <InnerPaperContentContainerSectionsContainer data-testid="join-orbi-pay-form">
        <InnerPaperContentContainerSection>
          <Box flex flexDirection="column" gap={16}>
            <Text variant="bodyMdBold" tx="label.join-orbi-pay.steps.1" />

            <WrapBox breakpoint="xs" flex gap={24}>
              <Box width="100%">
                <SelectBusinessType />
              </Box>

              <Box width="100%">
                <ControlledSelect
                  labelTx="label.join-orbi-pay.general-information.country.label"
                  name="countryCode"
                  options={COUNTRY_OPTIONS}
                />
              </Box>
            </WrapBox>

            <ControlledInput
              labelTx="label.join-orbi-pay.general-information.email.label"
              maxLength={EMAIL_MAX_LENGTH}
              name="email"
              required
            />
          </Box>
        </InnerPaperContentContainerSection>

        <InnerPaperContentContainerSection>
          <Box flex flexDirection="column" gap={16}>
            <Text variant="bodyMdBold" tx="label.join-orbi-pay.steps.2" />

            <WrapBox breakpoint="xs" flex gap={24}>
              <Box width="100%">
                <ControlledSelect
                  invertMenu
                  labelTx="label.join-orbi-pay.payout-information.account-holder-type.label"
                  name="accountHolderType"
                  options={ACCOUNT_HOLDER_TYPE_OPTIONS}
                  required
                />
              </Box>

              <Box width="100%">
                <ControlledSelect
                  invertMenu
                  labelTx="label.join-orbi-pay.payout-information.vat-percent.label"
                  name="vatPercent"
                  options={VAT_PERCENT_OPTIONS}
                />
              </Box>
            </WrapBox>

            <AccountHolderNameInput />

            <IBanInput />
          </Box>
        </InnerPaperContentContainerSection>

        <InnerPaperContentContainerSection>
          <Box gap={8} flex flexJustify="between" flexWrap>
            <TermsOfServiceCheckbox />

            <JoinOrbiPayButton />
          </Box>
        </InnerPaperContentContainerSection>
      </InnerPaperContentContainerSectionsContainer>
    </FormProvider>
  );
}

function OrbiPayNotOnboardedContent() {
  const publicStripeKey = useSelector(
    PublicStripeKeySelector.selectPublicStripeKey,
  );
  const publicStripeKeyStatus = useSelector(
    PublicStripeKeySelector.selectStatus,
  );

  const dispatch = useDispatch();

  const stripe = React.useMemo(async () => {
    if (!publicStripeKey) return null;

    return loadStripe(publicStripeKey);
  }, [publicStripeKey]);

  React.useEffect(() => {
    if (publicStripeKey) return;

    dispatch(getStripePublicKeyThunk());
  }, [dispatch, publicStripeKey]);

  if (publicStripeKeyStatus === 'pending') {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
        <ToolbarContentContainer>
          <BreadCrumbs>
            <BreadCrumb tx="label.breadcrumbs.orbi-pay.join-orbi-pay" isLast />
          </BreadCrumbs>
        </ToolbarContentContainer>
      </Toolbar>

      <Elements stripe={stripe}>
        <PaperContentContainer>
          <InnerPaperContentContainer>
            <JoinOrbiPayForm />
          </InnerPaperContentContainer>
        </PaperContentContainer>
      </Elements>
    </PageContainer>
  );
}

function OrbiPayMemberContent() {
  const orbiPayMember = useSelector(
    OrbiPaySettingsSelector.selectData,
  ) as unknown as OrbiPayMember;

  const parentDepartmentName = orbiPayMember.parentDepartmentName;

  return (
    <PageContainer>
      <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
        <ToolbarContentContainer>
          <BreadCrumbs>
            <BreadCrumb tx="label.breadcrumbs.orbi-pay.orbi-pay" isLast />
          </BreadCrumbs>
        </ToolbarContentContainer>
      </Toolbar>

      <ContentContainer>
        <InnerContentContainer>
          <Text
            color="pageTitle"
            variant="titleMd"
            tx="title.orbi-pay.member"
            data-testid="orbi-pay-member-title"
          />

          <Box>
            <Text variant="bodyMd" tx="label.orbi-pay.member.title" />
            <Text
              variant="bodyMd"
              tx="label.orbi-pay.member.subtitle"
              txArgs={{ departmentName: parentDepartmentName }}
            />
          </Box>
        </InnerContentContainer>
      </ContentContainer>
    </PageContainer>
  );
}

function FilterFromFormField() {
  const dispatch = useDispatch();

  const payoutsStartDate = useSelector(PayoutsSelector.selectStartDate);

  const setFromDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = new Date(e.target.value).getTime();

    if (isNaN(startDate)) {
      e.preventDefault();
      return;
    }

    dispatch(
      orbiPayActions.setPayoutsFiltering({
        startDate: setStartOfDay(
          startDate < FILTER_PAYOUTS_MIN_DATE
            ? FILTER_PAYOUTS_MIN_DATE
            : startDate,
        ).valueOf(),
      }),
    );
  };

  return (
    <Input
      labelTx="label.orbi-pay.filtering.from"
      min={formatDate(FILTER_PAYOUTS_MIN_DATE, 'YYYY-MM-DD')}
      onChange={setFromDate}
      type="date"
      value={formatDate(payoutsStartDate, 'YYYY-MM-DD')}
      width={300}
    />
  );
}

function FilterToFormField() {
  const dispatch = useDispatch();

  const payoutsEndDate = useSelector(PayoutsSelector.selectEndDate);

  const setEndDate: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const endDate = new Date(e.target.value).getTime();

    if (isNaN(endDate)) {
      e.preventDefault();
      return;
    }

    dispatch(
      orbiPayActions.setPayoutsFiltering({
        endDate: setEndOfDay(endDate).valueOf(),
      }),
    );
  };

  return (
    <Input
      labelTx="label.orbi-pay.filtering.to"
      max={formatDate(FILTER_PAYOUTS_MAX_DATE, 'YYYY-MM-DD')}
      onChange={setEndDate}
      type="date"
      value={formatDate(payoutsEndDate, 'YYYY-MM-DD')}
      width={300}
    />
  );
}

function FilterPayoutsForm() {
  return (
    <Box flex flexWrap gap={24}>
      <FilterFromFormField />
      <FilterToFormField />
    </Box>
  );
}

const TABLE_COLUMN_WIDTHS = {
  status: 200,
  createdAt: 200,
  amount: 200,
  actions: 40,
};

function OrbiPayPayouts() {
  const payouts = useSelector(PayoutsSelector.selectData);
  const payoutsFiltering = useSelector(PayoutsSelector.selectPayoutsFiltering);
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const payoutsStatus = useSelector(PayoutsSelector.selectStatus);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPayoutsThunk(payoutsFiltering));
  }, [dispatch, payoutsFiltering]);

  if (payoutsStatus === 'pending') {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.orbi-pay.table.status" />
            <TableHead tx="label.orbi-pay.table.created-at" />
            <TableHead tx="label.orbi-pay.table.amount" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody data-testid="orbi-pay-payouts-table-loading-state">
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (!payouts?.length) {
    return <EmptyState titleTx="label.orbi-pay.empty-state.title" />;
  }

  const renderPayoutTableRow = (payout: PayoutListItem) => {
    const downloadPayout = () => {
      dispatch(getPayoutThunk(payout.payoutId));
    };

    return (
      <TableRow key={payout.payoutId}>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.status}
          tx={getPayoutStatusTx(payout.status)}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.createdAt}
          text={formatDate(payout.createdAt, 'DD MMM YYYY')}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.amount}
          text={parseCurrency(payout.amount, currency)}
        />
        <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
          <Box ml="auto">
            <IconButton onClick={downloadPayout} icon="arrow-download-tray" />
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead tx="label.orbi-pay.table.status" />
          <TableHead tx="label.orbi-pay.table.created-at" />
          <TableHead tx="label.orbi-pay.table.amount" />
          <TableHead fixedRight />
        </TableRow>
      </TableHeader>

      <TableBody data-testid="orbi-pay-payouts-table">
        {payouts?.map(renderPayoutTableRow)}
      </TableBody>
    </Table>
  );
}

function UpdateSettingsButton() {
  const orbiPaySettings = useSelector(OrbiPaySettingsSelector.selectData);

  const navigateStripeConnectForm = useNavigateStripeConnectForm();

  const onUpdateInfoClick = () => {
    if (orbiPaySettings?.role !== OrbiPayRole.Admin) return;

    navigateStripeConnectForm({
      type: 'account_update',
      collect: orbiPaySettings.stripeRequirements?.requirementType,
    });
  };

  if (orbiPaySettings?.role !== OrbiPayRole.Admin) {
    return null;
  }

  return (
    <ResponsiveBox
      xs={
        <Button
          variant="primary"
          onClick={onUpdateInfoClick}
          tx="button.orbi-pay.update-info"
          data-testid="orbi-pay-update-info-button"
        />
      }
    >
      <Tooltip placement="left" titleTx="button.orbi-pay.update-info">
        <SolidIconButton
          onClick={onUpdateInfoClick}
          icon="pencil-square-outline"
        />
      </Tooltip>
    </ResponsiveBox>
  );
}

function OrbiPayAdminContent() {
  return (
    <PageContainer>
      <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
        <ToolbarContentContainer>
          <BreadCrumbs>
            <BreadCrumb tx="label.breadcrumbs.orbi-pay.orbi-pay" isLast />
          </BreadCrumbs>
        </ToolbarContentContainer>
      </Toolbar>

      <ContentContainer>
        <InnerContentContainer>
          <Box flexWrap gap={16} flexJustify="between" flex>
            <Text
              color="pageTitle"
              variant="titleMd"
              tx="title.orbi-pay.dashboard"
              data-testid="orbi-pay-admin-title"
            />

            <UpdateSettingsButton />
          </Box>

          <Box flex flexWrap>
            <FilterPayoutsForm />
          </Box>

          <OrbiPayPayouts />
        </InnerContentContainer>
      </ContentContainer>
    </PageContainer>
  );
}

export function OrbiPay() {
  const orbiPayRole = useSelector(OrbiPaySettingsSelector.selectOrbiPayRole);
  const orbiPaySettingsStatus = useSelector(
    OrbiPaySettingsSelector.selectStatus,
  );
  const createdOrbiPayAccountKey = useSelector(
    CreateOrbiPayAccountSelector.selectData,
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(orbiPayActions.clearCreateOrbiPayAccount());
    };
  }, [dispatch]);

  if (orbiPaySettingsStatus === 'pending' || !!createdOrbiPayAccountKey) {
    return <LoadingContainer />;
  }

  switch (orbiPayRole) {
    case OrbiPayRole.OrbiPayNotOnboarded:
      return <OrbiPayNotOnboardedContent />;

    case OrbiPayRole.Member:
      return <OrbiPayMemberContent />;

    case OrbiPayRole.Admin:
      return <OrbiPayAdminContent />;

    default:
      return null;
  }
}
