import {
  INITIAL_DATA_STATE,
  INITIAL_PAGINATION_STATE,
} from '../store.constants';
import {
  activityPostsAdapter,
  departmentPostsAdapter,
  postsAdapter,
} from './posts.adapter';
import { PostsState } from './posts.types';

export const initialPostsState: PostsState = {
  posts: {
    ...INITIAL_DATA_STATE,
    data: postsAdapter.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'createdAt' },
  },

  activityPosts: {
    ...INITIAL_DATA_STATE,
    data: activityPostsAdapter.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: undefined },
  },
  createActivityPost: INITIAL_DATA_STATE,
  updateActivityPost: INITIAL_DATA_STATE,

  departmentPosts: {
    ...INITIAL_DATA_STATE,
    data: departmentPostsAdapter.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: undefined },
  },
  createDepartmentPost: INITIAL_DATA_STATE,
  updateDepartmentPost: INITIAL_DATA_STATE,
};
