import styled, { css } from 'styled-components';

import { Box } from '../box';
import { IconButton } from '../icon-button';
import { Text } from '../text';
import {
  SEARCH_INPUT_BORDER_RADIUS,
  SEARCH_INPUT_X_PADDING,
  SEARCH_INPUT_Y_PADDING,
} from './search-input.constants';

interface StyledInputProps {
  hasError: boolean;
}

const InputBox = styled(Box)<{ hasValue: boolean }>`
  ${(props) => {
    const { hasValue } = props;

    return css`
      ${hasValue &&
      css`
        p {
          transform: scaleY(100%);
        }
      `}

      :focus-within {
        p {
          transform: scaleY(100%);
          opacity: 1;
        }
      }
    `;
  }}
`;

const ClearInputIconButton = styled(IconButton)<{ visible: boolean }>`
  ${(props) => {
    const { visible } = props;

    return css`
      ${!visible &&
      css`
        width: 0;
        height: 0;
        overflow: hidden;
        min-width: 0;
        min-height: 0;
      `}
    `;
  }}
`;

const ApplyInputText = styled(Text)<{ visible: boolean }>`
  ${(props) => {
    const { visible } = props;

    return css`
      ${!visible &&
      css`
        width: 0;
        height: 0;
        overflow: hidden;
        min-width: 0;
        min-height: 0;
      `}
    `;
  }}
`;

const Input = styled.input<StyledInputProps>`
  border: none;
  outline: none;
  width: 100%;
  font-weight: 300;
  height: 100%;

  ${(props) => {
    const { disabled, hasError, theme } = props;

    const inputStyles = css`
      border-width: 1px;
      border-style: solid;
      padding-left: ${SEARCH_INPUT_X_PADDING}px;
      padding-top: ${SEARCH_INPUT_Y_PADDING}px;
      padding-bottom: ${SEARCH_INPUT_Y_PADDING}px;
      padding-right: ${SEARCH_INPUT_X_PADDING}px;
      border-radius: ${SEARCH_INPUT_BORDER_RADIUS}px;
      font-size: ${theme.textVariants.bodyMd.fontSize};
      font-family: ${theme.textVariants.bodyMd.fontFamily};
      letter-spacing: ${theme.textVariants.bodyMd.letterSpacing};
      font-variation-settings: 'opsz' 20, 'GRAD' 0, 'slnt' 0, 'XTRA' 468,
        'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203,
        'YTFI' 738;

      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      ${!hasError &&
      css`
        :focus {
          background-color: ${theme.colors.inputBackgroundFocus};
          border-color: ${theme.colors.inputBorderFocus};
          color: ${theme.colors.inputTextFocus};
        }
      `}
    `;

    if (disabled) {
      return css`
        ${inputStyles};

        pointer-events: none;

        background-color: ${theme.colors.inputBackgroundDisabled};
        border-color: ${theme.colors.inputBorderDisabled};
        color: ${theme.colors.inputTextDisabled};
      `;
    }

    if (hasError) {
      return css`
        ${inputStyles};

        background-color: ${theme.colors.inputBackgroundError};
        border-color: ${theme.colors.inputBorderError};
        color: ${theme.colors.inputTextError};
      `;
    }

    return css`
      ${inputStyles};

      background-color: ${theme.colors.inputBackground};
      border-color: ${theme.colors.inputBorder};
      color: ${theme.colors.inputText};
    `;
  }};
`;

export const Styled = {
  ApplyInputText,
  ClearInputIconButton,
  Input,
  InputBox,
};
