import React from 'react';

import { InputTrailingElementProvider } from '../../context/input-trailing-element-context/input-trailing-element-context';
import { useCombinedRefs, useInputDynamicOffset } from '../../helpers';
import { translate } from '../../i18n';
import { Box } from '../box';
import { InputHelper } from '../input-helper';
import {
  SEARCH_INPUT_HEIGHT,
  SEARCH_INPUT_X_PADDING,
} from './search-input.constants';
import { Styled } from './search-input.styled';
import { SearchInputProps } from './search-input.types';

function useSearchInput(props: {
  maxLength?: number;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  value?: string;
}) {
  const { maxLength, onBlur, onChange, onFocus } = props;

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onFocus?.(e);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    onBlur?.(e);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const length = e.target.value.length;

    if (maxLength === undefined) {
      onChange?.(e);
      return;
    }

    if (length > maxLength) {
      return;
    }

    onChange?.(e);
  };

  return { handleBlur, handleChange, handleFocus };
}

function _SearchInput(
  props: SearchInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    disabled = false,
    error,
    errorTx,
    errorTxArgs,
    helperText,
    helperTx,
    helperTxArgs,
    leadingElement,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    placeholderTx,
    placeholderTxArgs,
    trailingElement,
    type,
    value,
    ...rest
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const trailingBoxRef = React.useRef<HTMLDivElement>(null);
  const leadingBoxRef = React.useRef<HTMLDivElement>(null);
  const combinedRefs = useCombinedRefs(ref, inputRef);

  const hasError = !!error || !!errorTx;

  useInputDynamicOffset({
    inputRef: combinedRefs,
    leadingBoxRef,
    trailingBoxRef,
    margin: SEARCH_INPUT_X_PADDING,
  });

  const { handleBlur, handleChange, handleFocus } = useSearchInput({
    maxLength,
    onBlur,
    onChange,
    onFocus,
  });

  return (
    <InputTrailingElementProvider
      leadingBoxRef={leadingBoxRef}
      trailingBoxRef={trailingBoxRef}
    >
      <Box relative width="100%">
        <Box height={SEARCH_INPUT_HEIGHT} relative>
          {leadingElement}

          <Styled.Input
            disabled={disabled}
            hasError={hasError}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={handleFocus}
            ref={combinedRefs}
            type={type}
            value={value}
            placeholder={
              placeholderTx
                ? translate(placeholderTx, placeholderTxArgs ?? {})
                : placeholder
            }
            {...rest}
          />

          {trailingElement}
        </Box>

        <InputHelper
          currentLength={value?.toString().length}
          disabled={disabled}
          hasError={hasError}
          helperText={error || helperText}
          helperTx={errorTx || helperTx}
          helperTxArgs={errorTxArgs || helperTxArgs}
          maxLength={maxLength}
        />
      </Box>
    </InputTrailingElementProvider>
  );
}

export const SearchInput = React.forwardRef(_SearchInput);
