import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import {
  activityPostsAdapter,
  departmentPostsAdapter,
  postsAdapter,
} from './posts.adapter';

export namespace PostsSelector {
  const selectSelf = (state: RootState) => state.posts.posts;

  export const selectPagination = createSelector(
    selectSelf,
    (posts) => posts.pagination,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (posts) => posts.status,
  );

  const selectData = createSelector(selectSelf, (posts) => posts.data);

  const postsSelectors = postsAdapter.getSelectors(selectData);

  export const selectAll = postsSelectors.selectAll;

  export const selectCount = postsSelectors.selectTotal;
}

export namespace CreateActivityPostSelector {
  export const selectStatus = (state: RootState) =>
    state.posts.createActivityPost.status;
}

export namespace UpdateActivityPostSelector {
  export const selectStatus = (state: RootState) =>
    state.posts.updateActivityPost.status;
}

export namespace ActivityPostsSelector {
  const selectSelf = (state: RootState) => state.posts.activityPosts;

  export const selectPagination = createSelector(
    selectSelf,
    (activityPosts) => activityPosts.pagination,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (activityPosts) => activityPosts.status,
  );

  const selectData = createSelector(
    selectSelf,
    (activityPosts) => activityPosts.data,
  );

  const activityPostsSelectors = activityPostsAdapter.getSelectors(selectData);

  export const selectAll = activityPostsSelectors.selectAll;

  export const selectCount = activityPostsSelectors.selectTotal;
}

export namespace DepartmentPostsSelector {
  const selectSelf = (state: RootState) => state.posts.departmentPosts;

  export const selectPagination = createSelector(
    selectSelf,
    (departmentPosts) => departmentPosts.pagination,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (departmentPosts) => departmentPosts.status,
  );

  const selectData = createSelector(
    selectSelf,
    (departmentPosts) => departmentPosts.data,
  );

  const departmentPostsSelectors =
    departmentPostsAdapter.getSelectors(selectData);

  export const selectAll = departmentPostsSelectors.selectAll;

  export const selectCount = departmentPostsSelectors.selectTotal;
}

export namespace CreateDepartmentPostSelector {
  const selectSelf = (state: RootState) => state.posts.createDepartmentPost;

  export const selectData = createSelector(
    selectSelf,
    (createDepartmentPost) => createDepartmentPost.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (createDepartmentPost) => createDepartmentPost.status,
  );
}

export namespace UpdateDepartmentPostSelector {
  const selectSelf = (state: RootState) => state.posts.updateDepartmentPost;

  export const selectData = createSelector(
    selectSelf,
    (updateDepartmentPost) => updateDepartmentPost.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (updateDepartmentPost) => updateDepartmentPost.status,
  );
}
