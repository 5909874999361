import { Breakpoint } from '@orbiapp/theme';
import styled, { css } from 'styled-components';

import { Box } from '../box';

interface ResponsiveBoxProps {
  breakpoint?: Breakpoint;
  nextBreakpoint?: Breakpoint;
}

const ResponsiveBox = styled(Box)<ResponsiveBoxProps>`
  ${(props) => {
    const { theme, breakpoint, nextBreakpoint } = props;

    if (nextBreakpoint && breakpoint) {
      const breakpointValue = theme.breakpoints[breakpoint];
      const nextBreakpointValue = theme.breakpoints[nextBreakpoint];

      return css`
        display: none;

        @media (min-width: ${breakpointValue}px) and (max-width: ${nextBreakpointValue -
          1}px) {
          display: flex;
        }
      `;
    }

    if (!nextBreakpoint && breakpoint) {
      const breakpointValue = theme.breakpoints[breakpoint];

      return css`
        display: none;

        @media (min-width: ${breakpointValue}px) {
          display: flex;
        }
      `;
    }

    if (nextBreakpoint && !breakpoint) {
      const nextBreakpointValue = theme.breakpoints[nextBreakpoint];

      return css`
        display: flex;

        @media (min-width: ${nextBreakpointValue}px) {
          display: none;
        }
      `;
    }
  }}
`;

export const Styled = { ResponsiveBox };
