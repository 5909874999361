import { CreateDepartmentPost, DepartmentPost } from '../../../../../../models';
import { CreateDepartmentPostDto, DepartmentPostDto } from './posts.dto';

export const departmentPostMapper = (
  departmentPost: DepartmentPostDto,
): DepartmentPost => ({
  createdAt: departmentPost.createdAt,
  departmentPostKey: departmentPost.postKey,
  message: departmentPost.message,
});

export const createDepartmentPostMapper = (
  createDepartmentPost: CreateDepartmentPost,
): CreateDepartmentPostDto => ({
  audiences: createDepartmentPost.audiences?.length
    ? createDepartmentPost.audiences
    : null,
  message: createDepartmentPost.message,
});
