import styled, { css } from 'styled-components';

import { Box } from '../box';
import { getTextStyles } from '../text';

interface ChipsInputBoxProps {
  disabled?: boolean;
  floatLabel: boolean;
  hasError?: boolean;
  showInput?: boolean;
}

const OuterChipsInputBox = styled(Box)`
  :focus-within {
    p {
      transform: scaleY(100%);
      opacity: 1;
    }
  }
`;

const ChipsInputBox = styled(Box)<ChipsInputBoxProps>`
  ${(props) => {
    const { disabled, hasError, floatLabel, showInput, theme } = props;

    const chipsInputBoxStyles = css`
      min-height: 72.8px;
      border-width: 1px;
      border-style: solid;

      input {
        ${getTextStyles('bodyMd', theme)}

        border: none;
        outline: none;
        height: 0;
        flex-grow: 1;
        line-height: 0;
        position: absolute;
        background-color: transparent;

        :focus {
          height: 30.8px;
          position: relative;
        }

        ${showInput &&
        css`
          height: 30.8px;
          position: relative;
        `}
      }

      label {
        transition: ${theme.transitions.fast};
        transition-property: top, font-variation-settings, font-size;
        top: 50%;
        transform: translateY(-50%);
      }
    `;

    if (disabled) {
      return css`
        ${chipsInputBoxStyles};

        pointer-events: none;

        background-color: ${theme.colors.inputBackgroundDisabled};
        border-color: ${theme.colors.inputBorderDisabled};
        color: ${theme.colors.inputTextDisabled};

        ${floatLabel &&
        css`
          label {
            ${getTextStyles('bodySm', theme)}
            top: 18px;
            color: ${theme.colors.inputLabelFocus};
          }
        `}

        ::placeholder {
          opacity: 0.5;
        }
      `;
    }

    if (hasError) {
      return css`
        ${chipsInputBoxStyles};

        background-color: ${theme.colors.inputBackgroundError};
        border-color: ${theme.colors.inputBorderError};
        color: ${theme.colors.inputTextError};

        ${floatLabel &&
        css`
          label {
            ${getTextStyles('bodySm', theme)}
            top: 18px;
            color: ${theme.colors.inputLabelFocus};
          }
        `}

        :focus-within {
          label {
            ${getTextStyles('bodySm', theme)}
            top: 18px;
            color: ${theme.colors.inputLabelFocus} !important;
          }
        }
      `;
    }

    return css`
      ${chipsInputBoxStyles};

      background-color: ${theme.colors.inputBackground};
      border-color: ${theme.colors.inputBorder};
      color: ${theme.colors.inputText};

      ${floatLabel &&
      css`
        label {
          ${getTextStyles('bodySm', theme)}
          top: 18px;
          color: ${theme.colors.inputLabelFocus};
        }
      `}

      :focus-within {
        border-color: ${theme.colors.inputBorderFocus};

        label {
          ${getTextStyles('bodySm', theme)}
          top: 18px;
          color: ${theme.colors.inputLabelFocus} !important;
        }
      }
    `;
  }}
`;

export const Styled = { ChipsInputBox, OuterChipsInputBox };
