import appQrCode from './app-qr-code.svg';
import inAssociationWithOrbi from './in-association-with-orbi.png';
import membershipsBulkInviteToolModal1 from './memberships-bulk-invite-tool-modal-1.png';
import membershipsBulkInviteToolModal2 from './memberships-bulk-invite-tool-modal-2.png';
import membershipsBulkInviteToolModal3 from './memberships-bulk-invite-tool-modal-3.png';
import offersModal1 from './offers-modal-1.png';
import offersModal2 from './offers-modal-2.png';
import offersModal3 from './offers-modal-3.png';
import orbiLogoGradientPng from './orbi-logo-gradient.png';
import orbiLogoOrange from './orbi-logo-orange.svg';
import redeemOffer from './redeem-offer.png';
import cash from './referral-step-cash.png';
import inbound from './referral-step-inbound.png';
import outbound from './referral-step-outbound.png';
import workspacesIntro from './workspaces-intro.mp4';

export const assets = {
  workspacesIntro,
  appQrCode,
  orbiLogoGradientPng,
  orbiLogoOrange,
  referrals: {
    cash,
    inbound,
    outbound,
  },
  inAssociationWithOrbi,
  redeemOffer,
  offersModal: {
    offersModal1,
    offersModal2,
    offersModal3,
  },
  membershipsBulkInviteToolModal: {
    membershipsBulkInviteToolModal1,
    membershipsBulkInviteToolModal2,
    membershipsBulkInviteToolModal3,
  },
};
