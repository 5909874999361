import styled, { css } from 'styled-components';

import {
  INPUT_BORDER_RADIUS,
  INPUT_X_PADDING,
  INPUT_Y_PADDING,
} from '../../constants';
import { Box } from '../box';
import { getTextStyles } from '../text';

const OuterPasswordInputBox = styled(Box)<{ floatLabel: boolean }>`
  ${(props) => {
    const { floatLabel } = props;

    return css`
      ${floatLabel &&
      css`
        p {
          transform: scaleY(100%);
        }
      `}

      :focus-within {
        p {
          transform: scaleY(100%);
          opacity: 1;
        }
      }
    `;
  }}
`;

const PasswordInputBox = styled(Box)<{ floatLabel: boolean }>`
  ${(props) => {
    const { floatLabel, theme } = props;

    return css`
      label {
        ${getTextStyles('bodyMd', theme)}
        transition: ${theme.transitions.fast};
        color: ${theme.colors.inputLabel};
        transition-property: top, font-variation-settings, font-size;
        top: 50%;
        transform: translateY(-50%);
      }

      ${floatLabel &&
      css`
        label {
          ${getTextStyles('bodySm', theme)}
          top: 18px;
          color: ${theme.colors.inputLabelFocus};
        }
      `}

      :focus-within {
        label {
          ${getTextStyles('bodySm', theme)}
          top: 18px;
          color: ${theme.colors.inputLabelFocus} !important;
        }
      }
    `;
  }}
`;

const PasswordInput = styled.input<{ hasError?: boolean }>`
  border: none;
  outline: none;
  width: 100%;
  height: 26px;
  font-weight: 300;
  height: 58px;

  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  ${(props) => {
    const { disabled, hasError, theme } = props;

    const passwordInputStyles = css`
      border-width: 1px;
      border-style: solid;
      padding-left: ${INPUT_X_PADDING}px;
      padding-top: ${INPUT_Y_PADDING * 2}px;
      padding-bottom: ${INPUT_Y_PADDING}px;
      padding-right: ${INPUT_X_PADDING}px;
      border-radius: ${INPUT_BORDER_RADIUS}px;

      -webkit-appearance: none;

      ::-webkit-date-and-time-value {
        text-align: left;
      }

      ${getTextStyles('bodyMd', theme)}

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }

      ::placeholder {
        ${getTextStyles('bodySm', theme)}
      }

      :-webkit-autofill + label {
        ${getTextStyles('bodySm', theme)}
        top: 18px;
      }

      :placeholder-shown + label {
        ${getTextStyles('bodySm', theme)}
        top: 18px;
      }

      ${!hasError &&
      css`
        :focus {
          background-color: ${theme.colors.inputBackgroundFocus};
          border-color: ${theme.colors.inputBorderFocus};
          color: ${theme.colors.inputTextFocus};
        }
      `}
    `;

    if (disabled) {
      return css`
        ${passwordInputStyles};

        pointer-events: none;

        background-color: ${theme.colors.inputBackgroundDisabled};
        border-color: ${theme.colors.inputBorderDisabled};
        color: ${theme.colors.inputTextDisabled};

        ::placeholder {
          opacity: 0.5;
        }
      `;
    }

    if (hasError) {
      return css`
        ${passwordInputStyles};

        background-color: ${theme.colors.inputBackgroundError};
        border-color: ${theme.colors.inputBorderError};
        color: ${theme.colors.inputTextError};
      `;
    }

    return css`
      ${passwordInputStyles};

      background-color: ${theme.colors.inputBackground};
      border-color: ${theme.colors.inputBorder};
      color: ${theme.colors.inputText};
    `;
  }}
`;

export const Styled = {
  PasswordInput,
  PasswordInputBox,
  OuterPasswordInputBox,
};
