import React from 'react';

import { Icon } from '../icon';
import { Text } from '../text';
import { Styled } from './link.styled';
import { LinkProps } from './link.types';

export function Link(props: LinkProps) {
  const {
    children,
    href,
    target,
    text,
    to,
    tx,
    txArgs,
    small,
    plain,
    state,
    ...rest
  } = props;

  if (plain) {
    if (href) {
      return (
        <Styled.PlainHrefLink href={href} target={target} {...rest}>
          {children}
        </Styled.PlainHrefLink>
      );
    }

    if (to) {
      return (
        <Styled.PlainToLink state={state} to={to} target={target} {...rest}>
          {children}
        </Styled.PlainToLink>
      );
    }

    return null;
  }

  const linkContent = (
    <React.Fragment>
      {children || (
        <Text
          as="span"
          text={text}
          tx={tx}
          txArgs={txArgs}
          variant={small ? 'bodySm' : 'bodyMd'}
          whiteSpace="nowrap"
        />
      )}

      {target === '_blank' && (
        <Icon name="arrow-top-right-on-square" size={20} />
      )}
    </React.Fragment>
  );

  if (to) {
    return (
      <Styled.ToLink state={state} to={to} target={target} {...rest}>
        {linkContent}
      </Styled.ToLink>
    );
  }

  return (
    <Styled.HrefLink href={href} target={target} {...rest}>
      {linkContent}
    </Styled.HrefLink>
  );
}
