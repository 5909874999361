import React from 'react';
import { useController } from 'react-hook-form';

import { INPUT_X_PADDING } from '../../constants';
import { TrailingInputBox } from '../../context/input-trailing-element-context';
import { useCombinedRefs } from '../../helpers';
import { translate } from '../../i18n';
import { isTxString } from '../../utils';
import { Box } from '../box';
import { IconButton } from '../icon-button';
import { InputHelper } from '../input-helper';
import { InputLabel } from '../input-label';
import { Styled } from './password-input.styled';
import {
  ControlledPasswordInputProps,
  PasswordInputProps,
  PasswordStrengthOptions,
} from './password-input.types';

const specialSymbolRegexp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;
const numberRegexp = /\d/g;

export function getPasswordStrength(
  password: string,
  options: PasswordStrengthOptions,
) {
  const strength = {
    score: 0,
    length: password.length,
    numbers: 0,
    specialCharacters: 0,
  };

  if (password.length >= options.minCharacters) {
    strength.score += 1;
  }

  strength['numbers'] = Array.from(password.matchAll(numberRegexp)).length;
  if (strength['numbers'] >= options.minNumbers) {
    strength.score += 1;
  }

  strength['specialCharacters'] = Array.from(
    password.matchAll(specialSymbolRegexp),
  ).length;
  if (strength['specialCharacters'] >= options.minSpecialCharacters) {
    strength.score += 1;
  }

  return strength;
}

function _ControlledPasswordInput(
  props: ControlledPasswordInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    errorTxArgs,
    defaultValue,
    deps,
    disabled,
    name,
    onBlur,
    onChange,
    onClear,
    minCharacters,
    minNumbers,
    minSpecialCharacters,
    ...rest
  } = props;

  const controller = useController({
    defaultValue,
    name,
    rules: { deps },
  });

  const combinedRefs = useCombinedRefs(ref, controller.field.ref);

  const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    controller.field.onChange(e);
    onChange?.(e);
  };

  const onBlurHandler: React.FocusEventHandler<HTMLInputElement> = (e) => {
    controller.field.onBlur();
    onBlur?.(e);
  };

  const onClearHandler = () => {
    onClear?.();
    controller.field.onChange({ target: { value: '' } });
  };

  const value = controller.field.value ?? '';

  const strength = getPasswordStrength(value, {
    minCharacters,
    minNumbers,
    minSpecialCharacters,
  });

  const _errorTxArgs = {
    ...errorTxArgs,
    count:
      strength.length < minCharacters
        ? minCharacters
        : strength.numbers < minNumbers
        ? minNumbers
        : strength.specialCharacters < minSpecialCharacters
        ? minSpecialCharacters
        : 0,
  };

  const errorTx = isTxString(controller.fieldState.error?.message, _errorTxArgs)
    ? controller.fieldState.error?.message
    : undefined;

  return (
    <PasswordInput
      disabled={disabled}
      errorTx={errorTx}
      errorTxArgs={_errorTxArgs}
      minCharacters={minCharacters}
      minNumbers={minNumbers}
      minSpecialCharacters={minSpecialCharacters}
      name={controller.field.name}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      onClear={onClearHandler}
      ref={combinedRefs}
      value={value}
      {...rest}
    />
  );
}

function _PasswordInput(
  props: PasswordInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    defaultValue,
    disabled = false,
    error,
    errorTx,
    errorTxArgs,
    helperText,
    helperTx,
    helperTxArgs,
    label,
    labelTx,
    labelTxArgs,
    minCharacters,
    minNumbers,
    minSpecialCharacters,
    onBlur,
    onChange,
    onClear,
    onFocus,
    placeholder,
    placeholderTx,
    placeholderTxArgs,
    value,
    width = '100%',
    ...rest
  } = props;

  const [type, setType] = React.useState<'password' | 'text'>('password');

  const inputRef = React.useRef<HTMLInputElement>(null);
  const labelRef = React.useRef<HTMLLabelElement>(null);

  const combinedRefs = useCombinedRefs(ref, inputRef);

  const hasError = !!error || !!errorTx;
  const hasValue = typeof value === 'string' ? !!value : value !== undefined;

  const hasDefaultValue =
    typeof defaultValue === 'string'
      ? !!defaultValue
      : defaultValue !== undefined;

  const floatLabel = hasValue || hasDefaultValue;

  const strength = getPasswordStrength(value, {
    minCharacters,
    minNumbers,
    minSpecialCharacters,
  });

  const handleClear = () => {
    onClear?.();
    inputRef.current?.focus();
  };

  const toggleInputType = () => {
    setType((type) => (type === 'password' ? 'text' : 'password'));

    inputRef.current?.focus();
  };

  return (
    <Styled.OuterPasswordInputBox
      floatLabel={floatLabel}
      relative
      width={width}
    >
      <Box relative>
        <Styled.PasswordInputBox floatLabel={floatLabel} relative>
          <Styled.PasswordInput
            defaultValue={defaultValue}
            disabled={disabled}
            hasError={hasError}
            onBlur={onFocus}
            onChange={onChange}
            onFocus={onBlur}
            ref={combinedRefs}
            type={type}
            value={value}
            placeholder={
              placeholderTx
                ? translate(placeholderTx, placeholderTxArgs ?? {})
                : placeholder
            }
            {...rest}
          />

          <InputLabel
            disabled={disabled}
            hasError={hasError}
            left={INPUT_X_PADDING}
            ref={labelRef}
            text={label}
            tx={labelTx}
            txArgs={labelTxArgs}
          />
        </Styled.PasswordInputBox>

        <TrailingInputBox>
          {value && onClear && (
            <IconButton
              disabled={disabled}
              icon="x-circle-outline"
              onClick={handleClear}
            />
          )}

          <IconButton
            disabled={disabled}
            icon={type === 'password' ? 'eye-outline' : 'eye-slash-outline'}
            onClick={toggleInputType}
          />
        </TrailingInputBox>
      </Box>

      <Box mt={8} px={INPUT_X_PADDING} flex gap={4}>
        <Box
          backgroundColor={
            strength.score < 1 && hasError
              ? 'passwordStrengthIndicatorBackgroundError'
              : strength.score > 0
              ? 'passwordStrengthIndicatorBackgroundSuccess'
              : 'passwordStrengthIndicatorBackground'
          }
          height={4}
          width="100%"
        />
        <Box
          backgroundColor={
            strength.score < 2 && hasError
              ? 'passwordStrengthIndicatorBackgroundError'
              : strength.score > 1
              ? 'passwordStrengthIndicatorBackgroundSuccess'
              : 'passwordStrengthIndicatorBackground'
          }
          height={4}
          width="100%"
        />
        <Box
          backgroundColor={
            strength.score < 3 && hasError
              ? 'passwordStrengthIndicatorBackgroundError'
              : strength.score > 2
              ? 'passwordStrengthIndicatorBackgroundSuccess'
              : 'passwordStrengthIndicatorBackground'
          }
          height={4}
          width="100%"
        />
      </Box>

      <InputHelper
        disabled={disabled}
        hasError={hasError}
        helperText={error || helperText}
        helperTx={errorTx || helperTx}
        helperTxArgs={errorTxArgs || helperTxArgs}
      />
    </Styled.OuterPasswordInputBox>
  );
}

export const ControlledPasswordInput = React.forwardRef(
  _ControlledPasswordInput,
);
export const PasswordInput = React.forwardRef(_PasswordInput);
