import { Text } from '../text';
import { Styled } from './chip.styled';
import { ChipProps, ChipVariant, GetChipColorsReturn } from './chip.types';

function getChipColors(variant: ChipVariant): GetChipColorsReturn {
  switch (variant) {
    case 'secondary':
      return {
        backgroundColor: 'chipSecondaryBackground',
        borderColor: 'chipSecondaryBorder',
        labelColor: 'chipSecondaryLabel',
      };

    case 'tertiary':
      return {
        backgroundColor: 'chipTertiaryBackground',
        borderColor: 'chipTertiaryBorder',
        labelColor: 'chipTertiaryLabel',
      };

    default:
      return {
        backgroundColor: 'chipPrimaryBackground',
        borderColor: 'chipPrimaryBorder',
        labelColor: 'chipPrimaryLabel',
      };
  }
}

export function Chip(props: ChipProps) {
  const {
    backgroundColor,
    borderColor,
    children,
    labelColor,
    text,
    tx,
    txArgs,
    variant = 'primary',
    ...rest
  } = props;

  const chipColors = getChipColors(variant);

  return (
    <Styled.Chip
      backgroundColor={backgroundColor || chipColors.backgroundColor}
      borderColor={borderColor || chipColors.borderColor}
      labelColor={labelColor || chipColors.labelColor}
      {...rest}
    >
      {children || (
        <Text
          as="span"
          tx={tx}
          txArgs={txArgs}
          text={text}
          variant="bodySm"
          lineHeight={20.8}
        />
      )}
    </Styled.Chip>
  );
}
