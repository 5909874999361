import React from 'react';

import { useOnClickOutside } from '../../helpers';
import { Box } from '../box';
import { Icon } from '../icon';
import { Menu, MenuItem, useMenu } from '../menu';
import { Text } from '../text';
import { Styled } from './tiny-select.styled';
import {
  TinySelectInputProps,
  TinySelectOptionProps,
  TinySelectProps,
} from './tiny-select.types';

export function TinySelectInput(props: TinySelectInputProps) {
  const { children, disabled, isOpen, value, chevronIconSize, ...rest } = props;

  return (
    <Styled.TinySelectInput
      disabled={disabled}
      flex
      flexAlign="center"
      flexJustify="between"
      gap={8}
      isOpen={isOpen}
      px={8}
      py={4}
      role="button"
      {...rest}
    >
      {children ?? (
        <React.Fragment>
          <Text
            text={value}
            variant="bodySm"
            color={disabled ? 'inputTextDisabled' : 'inputText'}
          />

          <Icon
            color={disabled ? 'inputIconDisabled' : 'inputIcon'}
            name={isOpen ? 'chevron-up' : 'chevron-down'}
            size={chevronIconSize}
          />
        </React.Fragment>
      )}
    </Styled.TinySelectInput>
  );
}

export function TinySelect<T extends string | number>(
  props: TinySelectProps<T>,
) {
  const {
    chevronIconSize = 20,
    defaultIsOpen,
    disabled = false,
    invertMenu,
    maxHeight,
    minWidth,
    onChange,
    options,
    renderOptionValue,
    renderValue,
    value,
    ...rest
  } = props;

  const { menuRef, isOpen, closeMenu, openMenu } = useMenu({ defaultIsOpen });

  useOnClickOutside({
    handler: closeMenu,
    isActive: isOpen,
    ref: menuRef as React.MutableRefObject<HTMLDivElement>,
  });

  const renderTinySelectOption = (option: TinySelectOptionProps<T>) => {
    const handleMenuItemClick = () => {
      closeMenu();
      onChange(option.value);
    };

    return (
      <MenuItem
        onClick={handleMenuItemClick}
        isSelected={value === option.value}
        key={option.value}
        disabled={option.disabled}
      >
        {renderOptionValue ? (
          renderOptionValue(option)
        ) : (
          <Text text={option.text} tx={option.tx} variant="bodySm" as="span" />
        )}
      </MenuItem>
    );
  };

  return (
    <Box ref={menuRef} relative>
      <TinySelectInput
        chevronIconSize={chevronIconSize}
        disabled={disabled}
        isOpen={isOpen}
        onClick={isOpen ? closeMenu : openMenu}
        value={value}
        {...rest}
      >
        {renderValue?.(options.find((option) => option.value === value)!)}
      </TinySelectInput>

      <Menu
        absolute
        bottom={invertMenu ? '100%' : undefined}
        width="100%"
        isOpen={isOpen}
        mb={invertMenu ? 10 : undefined}
        mt={invertMenu ? undefined : 10}
        top={invertMenu ? undefined : '100%'}
        maxHeight={maxHeight}
        minWidth={minWidth}
      >
        {options.map(renderTinySelectOption)}
      </Menu>
    </Box>
  );
}
