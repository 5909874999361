export function isObject(item: any): item is object {
  return !!item && typeof item === 'object' && !Array.isArray(item);
}

export function sumRecordValues(record: Record<string, number>) {
  return Object.values(record).reduce((acc, curr) => acc + curr, 0);
}

export function deepCopy<T extends object>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function getObjectValue<T>(object: object, path: string): T | undefined {
  let output: any = object;
  const parts = path.split('.');

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    if (/\[\d.*\]/.test(part)) {
      output = output[parseInt(part.replace('[', '').replace(']', ''))];
      continue;
    }

    if (output[part] === undefined) {
      return undefined;
    }

    output = output[part];
  }

  return output as T;
}

export function deepMerge<T extends Record<string, any>>(
  target: T,
  ...sources: Record<string, any>[]
): T {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return deepMerge(target, ...sources);
}

export function flattenFieldErrorsObject(obj: Record<string, any>) {
  const toReturn: Record<string, any> = {};

  for (const key in obj) {
    obj['ref'] = null;

    if (!obj.hasOwnProperty(key)) continue;

    if (typeof obj[key] == 'object' && obj[key] !== null) {
      const flatObject = flattenFieldErrorsObject(obj[key]);

      for (const nestedKey in flatObject) {
        if (!flatObject.hasOwnProperty(nestedKey)) continue;

        if (flatObject[nestedKey]) {
          toReturn[key + '.' + nestedKey] = flatObject[nestedKey];
        }
      }

      continue;
    }

    toReturn[key] = obj[key];
  }

  return toReturn;
}
