import React from 'react';

export function usePlacementProps(ref: React.RefObject<HTMLElement>) {
  const [placementProps, setPlacementProps] = React.useState({
    top: 0,
    left: 0,
    width: 0,
  });

  const updatePlacementProps = React.useCallback(() => {
    if (!ref.current) {
      return;
    }

    const placeTargetRect = ref.current.getBoundingClientRect();

    const left = placeTargetRect.left;
    const top = placeTargetRect.top + placeTargetRect.height;
    const width = placeTargetRect.width;

    setPlacementProps({ top, left, width });
  }, [ref]);

  return [placementProps, updatePlacementProps] as const;
}
