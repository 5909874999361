import React from 'react';

export function useInputDynamicOffset(props: {
  inputRef: React.RefObject<HTMLElement>;
  labelRef?: React.RefObject<HTMLElement>;
  leadingBoxRef: React.RefObject<HTMLDivElement>;
  trailingBoxRef: React.RefObject<HTMLDivElement>;
  margin: number;
}) {
  const { inputRef, labelRef, leadingBoxRef, trailingBoxRef, margin } = props;

  React.useEffect(() => {
    if (!inputRef.current || !leadingBoxRef.current) {
      return;
    }

    const { width } = leadingBoxRef.current.getBoundingClientRect();

    inputRef.current.style.paddingLeft = `${width + margin + 8}px`;

    if (labelRef?.current) {
      labelRef.current.style.left = `${width + margin + 8}px`;
    }
  }, [inputRef, labelRef, leadingBoxRef, margin]);

  React.useEffect(() => {
    if (!inputRef.current || !trailingBoxRef.current) {
      return;
    }

    const { width } = trailingBoxRef.current.getBoundingClientRect();

    inputRef.current.style.paddingRight = `${width + margin + 8}px`;
  }, [inputRef, trailingBoxRef, margin]);
}
