import { Pagination } from '@orbiapp/components';

import { AsyncState } from '../models';

export const INITIAL_DATA_STATE: AsyncState<any> = {
  data: null,
  error: null,
  status: 'idle',
};

export const INITIAL_PAGINATION_STATE: Omit<Pagination, 'orderBy'> = {
  offset: 0,
  limit: 25,
  sortOrder: 'desc',
};
