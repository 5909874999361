import React from 'react';

import { Icon } from '../icon';
import { Link } from '../link';
import { Spinner } from '../spinner';
import { Styled } from './icon-button.styled';
import { IconButtonProps } from './icon-button.types';

function IconButtonContent(
  props: Pick<
    IconButtonProps,
    | 'isLoading'
    | 'icon'
    | 'isActive'
    | 'isActiveIcon'
    | 'iconSize'
    | 'disabled'
    | 'children'
  >,
) {
  const {
    children,
    disabled,
    icon,
    iconSize,
    isActive,
    isActiveIcon,
    isLoading,
  } = props;

  if (isLoading) {
    return <Spinner size={20} />;
  }

  if (children) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (icon) {
    return (
      <Icon
        zIndex={2}
        absolute
        name={isActive && isActiveIcon && !disabled ? isActiveIcon : icon}
        size={iconSize}
      />
    );
  }

  return null;
}

function _SolidIconButton(
  props: IconButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    children,
    disabled,
    href,
    icon,
    iconSize = 24,
    isLoading,
    to,
    isActiveIcon,
    isActive,
    state,
    ...rest
  } = props;

  const iconButton = (
    <Styled.SolidIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isActive={isActive}
      isLoading={isLoading}
      ref={ref}
      role="button"
      type="button"
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.SolidIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}
export const SolidIconButton = React.forwardRef(_SolidIconButton);

export function OnDarkIconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    href,
    icon,
    iconSize = 24,
    isActive,
    isActiveIcon,
    isLoading,
    state,
    to,
    ...rest
  } = props;

  const iconButton = (
    <Styled.OnDarkIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isLoading={isLoading}
      role="button"
      type="button"
      isActive={isActive}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.OnDarkIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}

export function SuccessIconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    href,
    icon,
    iconSize = 24,
    isActive,
    isActiveIcon,
    isLoading,
    state,
    to,
    ...rest
  } = props;

  const iconButton = (
    <Styled.SuccessIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isLoading={isLoading}
      role="button"
      type="button"
      isActive={isActive}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.SuccessIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}

export function DangerIconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    href,
    icon,
    iconSize = 24,
    isActive,
    isActiveIcon,
    isLoading,
    state,
    to,
    ...rest
  } = props;

  const iconButton = (
    <Styled.DangerIconButton
      aria-disabled={disabled}
      disabled={disabled}
      isLoading={isLoading}
      role="button"
      type="button"
      isActive={isActive}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.DangerIconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}

export function IconButton(props: IconButtonProps) {
  const {
    children,
    disabled,
    href,
    icon,
    iconSize = 24,
    isActive,
    isActiveIcon,
    isLoading,
    state,
    to,
    ...rest
  } = props;

  const iconButton = (
    <Styled.IconButton
      aria-disabled={disabled}
      disabled={disabled}
      isLoading={isLoading}
      role="button"
      type="button"
      isActive={isActive}
      {...rest}
    >
      <IconButtonContent
        disabled={disabled}
        icon={icon}
        iconSize={iconSize}
        isActive={isActive}
        isActiveIcon={isActiveIcon}
        isLoading={isLoading}
      >
        {children}
      </IconButtonContent>
    </Styled.IconButton>
  );

  if (to || href) {
    return (
      <Link state={state} href={href} to={to} plain>
        {iconButton}
      </Link>
    );
  }

  return iconButton;
}
