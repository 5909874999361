import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledInput,
  IconButton,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  Switch,
  TrailingInputBox,
  flattenFieldErrorsObject,
  getUID,
} from '@orbiapp/components';
import React from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  CreateMembershipMultichoiceQuestionValidation,
  MULTICHOICE_OPTION_LABEL_MAX_LENGTH,
  MULTICHOICE_QUESTION_MAX_LENGTH,
  MembershipMultichoiceOptionForm,
  MembershipMultichoiceQuestionForm,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import { RequestedInfoFormHeader } from '../requested-info-form-header';

function MultiChoiceOptions(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const { fields, remove } = useFieldArray<MembershipMultichoiceQuestionForm>({
    name: 'options',
  });

  const renderMultiChoiceOption = (
    field: (typeof fields)[number],
    index: number,
  ) => {
    const removeOption = () => remove(index);

    return (
      <ControlledInput
        disabled={isLoading}
        key={field.id}
        labelTx="label.memberships.create-membership.questions.option"
        maxLength={MULTICHOICE_OPTION_LABEL_MAX_LENGTH}
        name={`options.${index}.label`}
        required
        trailingElement={
          <TrailingInputBox>
            <IconButton
              disabled={fields.length <= 2 || isLoading}
              icon="trash-outline"
              onClick={removeOption}
            />
          </TrailingInputBox>
        }
      />
    );
  };

  return <React.Fragment>{fields.map(renderMultiChoiceOption)}</React.Fragment>;
}

function ToggleIsRequired(props: { isLoading?: boolean }) {
  const { isLoading } = props;

  const { setValue, watch } =
    useFormContext<MembershipMultichoiceQuestionForm>();
  const { isRequired } = watch();

  const toggleIsRequired = () => setValue('isRequired', !isRequired);

  return (
    <Switch
      checked={isRequired}
      disabled={isLoading}
      onClick={toggleIsRequired}
      tx="label.memberships.create-membership.questions.is-required"
    />
  );
}

export function EditMultiChoiceForm(props: {
  closeModal: () => void;
  defaultValues: MembershipMultichoiceQuestionForm;
  isLoading?: boolean;
  onSubmitted: (data: MembershipMultichoiceQuestionForm) => void;
}) {
  const { closeModal, defaultValues, isLoading, onSubmitted } = props;

  const formMethods = useForm<MembershipMultichoiceQuestionForm>({
    defaultValues,
    resolver: joiResolver(CreateMembershipMultichoiceQuestionValidation),
  });

  const addMultichoiceQuestion = formMethods.handleSubmit(
    (data) => {
      onSubmitted(data);
      formMethods.reset();
    },
    (err) => {
      Logger.warning('createMembershipMultichoiceQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const addMultichoiceOption = () => {
    const { options } = formMethods.getValues();

    const newOption: MembershipMultichoiceOptionForm = {
      index: options.length + 1,
      key: getUID(),
      label: '',
      isNew: true,
    };

    const newOptions = options.map((option, index) => ({
      ...option,
      index,
    }));

    formMethods.setValue('options', [...newOptions, newOption]);
  };

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <ModalContentContainer>
        <ModalHeaderContainer>
          <ModalTitle tx="label.memberships.view-membership.questions.edit-title" />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          <Box flexDirection="column" flex gap={24}>
            <ToggleIsRequired isLoading={isLoading} />

            <ControlledInput
              disabled={isLoading}
              labelTx="label.memberships.create-membership.questions.question"
              maxLength={MULTICHOICE_QUESTION_MAX_LENGTH}
              name="question"
              required
            />

            <MultiChoiceOptions isLoading={isLoading} />

            <Button
              disabled={isLoading}
              mx="auto"
              onClick={addMultichoiceOption}
              variant="secondary"
              tx="label.memberships.create-membership.questions.add-option"
            />
          </Box>
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button onClick={closeModal} tx="button.cancel" variant="tertiary" />
          <Button
            isLoading={isLoading}
            onClick={addMultichoiceQuestion}
            tx="button.save"
            variant="secondary"
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </FormProvider>
  );
}

export function MultiChoiceForm(props: {
  defaultValues: MembershipMultichoiceQuestionForm;
  disableSelectType?: boolean;
  isLoading?: boolean;
  onSubmitted: (data: MembershipMultichoiceQuestionForm) => void;
  setDefaultValues: (data: MembershipMultichoiceQuestionForm) => void;
  titleTx: TxString;
}) {
  const {
    defaultValues,
    disableSelectType,
    isLoading,
    onSubmitted,
    setDefaultValues,
    titleTx,
  } = props;

  const formMethods = useForm<MembershipMultichoiceQuestionForm>({
    defaultValues,
    resolver: joiResolver(CreateMembershipMultichoiceQuestionValidation),
  });

  const addMultichoiceQuestion = formMethods.handleSubmit(
    (data) => {
      onSubmitted(data);
      formMethods.reset();
    },
    (err) => {
      Logger.warning('updateMembershipMultichoiceQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const addMultichoiceOption = () => {
    const { options } = formMethods.getValues();

    const newOption: MembershipMultichoiceOptionForm = {
      index: options.length + 1,
      key: getUID(),
      label: '',
      isNew: true,
    };

    const newOptions = options.map((option, index) => ({
      ...option,
      index,
    }));

    formMethods.setValue('options', [...newOptions, newOption]);
  };

  React.useEffect(() => {
    formMethods.reset(defaultValues);
  }, [defaultValues, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <RequestedInfoFormHeader
          defaultValues={defaultValues}
          disableSelectType={disableSelectType}
          setDefaultValues={setDefaultValues}
          titleTx={titleTx}
        />

        <Box flex flexDirection="column" gap={24}>
          <ToggleIsRequired />

          <ControlledInput
            disabled={isLoading}
            labelTx="label.memberships.create-membership.questions.question"
            maxLength={MULTICHOICE_QUESTION_MAX_LENGTH}
            name="question"
            required
          />

          <MultiChoiceOptions />

          <Button
            disabled={isLoading}
            mx="auto"
            onClick={addMultichoiceOption}
            variant="secondary"
            tx="label.memberships.create-membership.questions.add-option"
          />
        </Box>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={addMultichoiceQuestion}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}
