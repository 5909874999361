import { Styled } from './switch.styled';
import { SwitchProps } from './switch.types';

export function Switch(props: SwitchProps) {
  const {
    disabled,
    checked,
    onClick,
    tx,
    text,
    txArgs,
    switchPlacement,
    ...rest
  } = props;

  const switchContainer = (
    <Styled.SwitchContainer flex flexAlign="center">
      <Styled.Switch
        aria-checked={checked}
        aria-disabled={disabled}
        checked={checked}
        disabled={disabled}
        onClick={tx || text ? undefined : onClick}
        role="switch"
      >
        <Styled.BlobOverlay disabled={disabled} checked={checked} />

        <Styled.SwitchIcon
          checked={checked}
          name={checked ? 'check-circle-outline' : 'minus-circle-outline'}
          size={16}
        />
      </Styled.Switch>

      {/* TODO - This is temporary */}
      <input checked={checked} hidden readOnly type="checkbox" {...rest} />
    </Styled.SwitchContainer>
  );

  if (tx || text) {
    return (
      <Styled.SwitchTextBox
        checked={checked}
        cursor={disabled ? 'auto' : 'pointer'}
        disabled={disabled}
        flex
        flexAlign="center"
        gap={8}
        onClick={disabled ? undefined : onClick}
        width="fit-content"
        flexDirection={switchPlacement === 'right' ? 'row' : 'row-reverse'}
      >
        <Styled.SwitchText
          text={text}
          tx={tx}
          txArgs={txArgs}
          variant="bodyMd"
        />

        {switchContainer}
      </Styled.SwitchTextBox>
    );
  }

  return switchContainer;
}
