import { createEntityAdapter } from '@reduxjs/toolkit';

import { ActivityPost, DepartmentPost, Post } from '../../models';

export const postsAdapter = createEntityAdapter<Post, string>({
  selectId: (post) => post.postKey,
  sortComparer: (a, b) => b.createdAt - a.createdAt,
});

export const activityPostsAdapter = createEntityAdapter<ActivityPost, string>({
  selectId: (post) => post.activityPostKey,
});

export const departmentPostsAdapter = createEntityAdapter<
  DepartmentPost,
  string
>({
  selectId: (post) => post.departmentPostKey,
});
