import React, { PropsWithChildren, forwardRef } from 'react';

import { INPUT_X_PADDING } from '../../constants';
import { Styled } from './input-trailing-element-context.styled';
import {
  InputIconBoxProps,
  InputRefContextType,
} from './input-trailing-element-context.types';

const InputIconBox = forwardRef<HTMLDivElement, InputIconBoxProps>(
  (props, ref) => {
    const { children, ...rest } = props;

    const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

    return (
      <Styled.IconInputBoxProps
        absolute
        flex
        flexAlign="center"
        gap={16}
        onClick={stopPropagation}
        ref={ref}
        right={INPUT_X_PADDING}
        zIndex={1}
        {...rest}
      >
        {children}
      </Styled.IconInputBoxProps>
    );
  },
);

export const InputTrailingElementContext =
  React.createContext<InputRefContextType>({
    leadingBoxRef: React.createRef<HTMLDivElement>(),
    trailingBoxRef: React.createRef<HTMLDivElement>(),
    extraTrailingElements: null,
  });

export function InputTrailingElementProvider(
  props: PropsWithChildren<{
    extraTrailingElements?: InputRefContextType['extraTrailingElements'];
    leadingBoxRef: InputRefContextType['leadingBoxRef'];
    trailingBoxRef: InputRefContextType['leadingBoxRef'];
  }>,
) {
  const {
    children,
    leadingBoxRef,
    trailingBoxRef,
    extraTrailingElements = null,
  } = props;

  return (
    <InputTrailingElementContext.Provider
      value={{ extraTrailingElements, leadingBoxRef, trailingBoxRef }}
    >
      {children}
    </InputTrailingElementContext.Provider>
  );
}

export function TrailingInputBox(props: React.PropsWithChildren) {
  const { children } = props;

  const { trailingBoxRef, extraTrailingElements } = React.useContext(
    InputTrailingElementContext,
  );

  return (
    <InputIconBox align="right" ref={trailingBoxRef}>
      {children}

      {extraTrailingElements}
    </InputIconBox>
  );
}

export function LeadingInputBox(props: React.PropsWithChildren) {
  const { children } = props;

  const { leadingBoxRef } = React.useContext(InputTrailingElementContext);

  return (
    <InputIconBox align="left" ref={leadingBoxRef}>
      {children}
    </InputIconBox>
  );
}
