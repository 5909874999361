import { Breakpoint, breakpoints } from '@orbiapp/theme';
import React from 'react';

import { BoxProps } from '../box';
import { Styled } from './responsive-box.styled';

interface ResponsiveBoxProps
  extends BoxProps,
    Partial<Record<Breakpoint, React.ReactNode>> {}

export function ResponsiveBox(props: ResponsiveBoxProps) {
  const { children, xxs, xs, sm, md, lg, xl, ...rest } = props;

  const breakpointValues = { xxs, xs, sm, md, lg, xl };

  const usedBreakpoints = Object.keys(breakpointValues) as Breakpoint[];

  const getNextBreakpoint = (breakpoint?: Breakpoint) => {
    const breakpointsArray = Object.keys(breakpoints) as Breakpoint[];

    let start = 0;
    if (breakpoint) {
      start = breakpointsArray.indexOf(breakpoint) + 1;
    }

    for (let i = start; i < breakpointsArray.length; i++) {
      const currentBreakpoint = breakpointsArray[i];
      const node = breakpointValues[currentBreakpoint];

      if (React.isValidElement(node) || node === null) {
        return currentBreakpoint;
      }
    }

    return undefined;
  };

  const renderBreakpoint = (breakpoint: Breakpoint) => {
    if (!breakpointValues[breakpoint]) {
      return null;
    }

    return (
      <Styled.ResponsiveBox
        breakpoint={breakpoint}
        nextBreakpoint={getNextBreakpoint(breakpoint)}
        key={breakpoint}
        {...rest}
      >
        {breakpointValues[breakpoint]}
      </Styled.ResponsiveBox>
    );
  };

  return (
    <React.Fragment>
      {children && (
        <Styled.ResponsiveBox nextBreakpoint={getNextBreakpoint()} {...rest}>
          {children}
        </Styled.ResponsiveBox>
      )}

      {usedBreakpoints.map(renderBreakpoint)}
    </React.Fragment>
  );
}
