import {
  Address,
  Box,
  Button,
  ControlledInput,
  DangerIconButton,
  FormSection,
  FormSectionHeader,
  Icon,
  IconButton,
  Input,
  LeadingInputBox,
  Location,
  Menu,
  MenuItem,
  Spinner,
  Text,
  TrailingInputBox,
  getUID,
  isTxString,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  useCoordinatesSearch,
  useLocationSearch,
} from '../../../../../helpers';
import { CreateOfferForm } from '../../../../../models';
import { LocationsSelector, useSelector } from '../../../../../store';
import { useOfferFormsSteps } from '../../helpers';

const sessionToken = getUID();

function LocationInput(props: { index: number }) {
  const { index } = props;

  const suggestions = useSelector(LocationsSelector.selectAll);

  const formContext = useFormContext<CreateOfferForm>();

  const location = formContext.getValues(`locations.${index}`);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState(location.address);

  const menuState = useMenu();

  const { status, search, searchResult } = useLocationSearch(sessionToken);
  const { search: searchCoordinates } = useCoordinatesSearch(sessionToken);

  const hasPicked = Boolean(location.latitude && location.longitude);

  const isOpen =
    !hasPicked &&
    menuState.isOpen &&
    (searchResult.length || (!value.length && suggestions.length));

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);

    if (e.target.value.trim()) {
      search(e.target.value);
    }
  };

  const clearLocation = () => {
    formContext.setValue(
      `locations.${index}`,
      {
        address: '',
        label: location.label,
        latitude: 0,
        longitude: 0,
      },
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
    setValue('');

    window.setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const preventDefault: React.MouseEventHandler<HTMLElement> = (e) => {
    e.preventDefault();
  };

  const renderSearchResult = (address: Address) => {
    const handleSelect = async () => {
      const coordinates = await searchCoordinates(address.placeId);
      if (coordinates) {
        formContext.setValue(
          `locations.${index}`,
          {
            address: address.address,
            label: '',
            latitude: coordinates.latitude,
            longitude: coordinates.longitude,
          },
          {
            shouldDirty: true,
            shouldValidate: formContext.formState.isSubmitted,
          },
        );

        setValue(address.address);

        formContext.setFocus(`locations.${index}.label`);
      }
    };

    return (
      <MenuItem
        key={address.placeId}
        onClick={handleSelect}
        onMouseDown={preventDefault}
      >
        <Text variant="bodyMd" text={address.address} />
      </MenuItem>
    );
  };

  const renderSuggestion = (location: Location) => {
    const handleSelect = () => {
      formContext.setValue(
        `locations.${index}`,
        {
          address: location.address,
          label: location.label,
          latitude: location.latitude,
          longitude: location.longitude,
        },
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );

      setValue(location.address);
    };

    return (
      <MenuItem
        key={location.address}
        onClick={handleSelect}
        onMouseDown={preventDefault}
      >
        <Text variant="bodyMd" text={location.address} />
      </MenuItem>
    );
  };

  const errorTx = isTxString(
    formContext.formState.errors.locations?.[index]?.address?.message,
  )
    ? formContext.formState.errors.locations?.[index]?.address?.message
    : undefined;

  const searchResultKeys = new Set(searchResult.map((item) => item.address));

  return (
    <Box ref={menuState.menuRef} flexGrow={1} relative>
      <Input
        ref={inputRef}
        disabled={hasPicked}
        onChange={handleChange}
        value={value}
        onFocus={menuState.openMenu}
        onBlur={menuState.closeMenu}
        errorTx={errorTx as TxString}
        leadingElement={
          <LeadingInputBox>
            <Icon name="magnifying-glass" />
          </LeadingInputBox>
        }
        trailingElement={
          <TrailingInputBox>
            {status === 'pending' && <Spinner size={20} />}

            {!hasPicked ? (
              <IconButton
                onClick={menuState.toggleMenu}
                icon={isOpen ? 'chevron-up' : 'chevron-down'}
              />
            ) : (
              <IconButton onClick={clearLocation} icon="x-mark" />
            )}
          </TrailingInputBox>
        }
        labelTx="placeholder.select-location"
      />

      <Menu
        maxHeight={300}
        bottom="100%"
        mb={8}
        absolute
        isOpen={Boolean(isOpen)}
        width="100%"
      >
        {searchResult.map(renderSearchResult)}

        {!value.length &&
          suggestions
            .filter((item) => !searchResultKeys.has(item.address))
            .map(renderSuggestion)}

        {searchResult.length === 0 && value.length > 0 && (
          <MenuItem>
            <Text variant="bodyMd" tx="placeholder.no-addresses" />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

function LabelInputTrailingBox(props: { index: number }) {
  const { index } = props;

  const formContext = useFormContext<CreateOfferForm>();

  const label = formContext.watch(`locations.${index}.label`);

  const clearLabel = () => {
    formContext.setValue(`locations.${index}.label`, '', {
      shouldDirty: true,
      shouldValidate: formContext.formState.isSubmitted,
    });
    formContext.setFocus(`locations.${index}.label`);
  };

  return (
    <TrailingInputBox>
      {label && <IconButton onClick={clearLabel} icon="x-mark" />}
    </TrailingInputBox>
  );
}

export function Locations() {
  const formContext = useFormContext<CreateOfferForm>();

  const locations = formContext.watch('locations');

  const steps = useOfferFormsSteps();

  const addLocation = () => {
    formContext.setValue('locations', [
      ...(locations ?? []),
      {
        address: '',
        label: '',
        latitude: 0,
        longitude: 0,
      },
    ]);
  };

  const renderLocation = (_: any, index: number) => {
    const removeLocation = () => {
      formContext.setValue(
        'locations',
        (formContext.getValues('locations') ?? []).filter(
          (_, i) => i !== index,
        ),
        {
          shouldDirty: true,
          shouldValidate: formContext.formState.isSubmitted,
        },
      );
    };

    return (
      <Box key={`location-${index}`} gap={16} flex>
        <Box flex flexDirection="column" gap={8} flexGrow={1}>
          <LocationInput index={index} />

          <ControlledInput
            labelTx="label.offer-form.location-label"
            name={`locations.${index}.label`}
            trailingElement={<LabelInputTrailingBox index={index} />}
          />
        </Box>

        <DangerIconButton
          icon="minus-circle-outline"
          mt={54}
          onClick={removeLocation}
        />
      </Box>
    );
  };

  return (
    <FormSection>
      <FormSectionHeader>
        <Box flex flexJustify="between" gap={16} flexAlign="start">
          <Box>
            <Box flex gap={4}>
              <Text
                tx="label.offer-form.headers.locations"
                variant="bodyMdBold"
                color="formSectionHeader"
                txArgs={{ step: steps.locations }}
              />
              <Text
                tx="label.general.optional"
                variant="bodyMdItalic"
                color="formSectionHeader"
              />
            </Box>
          </Box>

          <Button
            variant="secondary"
            tx="button.add-location"
            onClick={addLocation}
          />
        </Box>
      </FormSectionHeader>

      <Box flexGrow={1}>
        <Box flex flexDirection="column" gap={16}>
          {locations?.map(renderLocation)}
        </Box>
      </Box>
    </FormSection>
  );
}
