import readXlsxFile from 'read-excel-file';

import { FileType } from '../components';

export const bytesToMb = (bytes: number) =>
  `${(bytes / 1000 / 1000).toFixed()}MB`;

export const isAcceptedFileType = (fileType: string): fileType is FileType => {
  return new Set(Object.values(FileType)).has(fileType as any);
};

async function parseExcelFile(file: File): Promise<string> {
  const result = await readXlsxFile(file);

  let str = '';

  result.forEach((row) => {
    row.forEach((cell) => {
      if (cell) {
        str += cell + '\n';
      }
    });
  });

  return str;
}

function parseCSVOrTextFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);

    reader.onload = (e) => {
      const result = reader.result?.toString();

      if (!result) {
        reject(e);
        return;
      }

      resolve(result);
    };

    reader.onerror = reject;
    reader.onabort = reject;
  });
}

export function parseFileAsText(file: File): Promise<string> {
  if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
    return parseExcelFile(file);
  }

  return parseCSVOrTextFile(file);
}

export interface UploadCsvResult {
  rows: string[];
  name: string;
  fileType: 'text/csv';
}
export async function parseCSVCodes(file: File): Promise<UploadCsvResult> {
  const result = await parseFileAsText(file);

  const rows = result
    .split('\n')
    .map((row) => row.replace('\r', '').replace(';', ''));

  return {
    rows,
    name: file.name,
    fileType: 'text/csv',
  };
}

export function base64FileSize(base64: string) {
  const base64Length = base64.length;
  const padding = (base64.match(/=/g) || []).length;

  return base64Length * 0.75 - padding;
}
