import styled, { css } from 'styled-components';

import { baseComponentStyles } from '../../utils';
import { Box } from '../box';
import { getTextStyles } from '../text';
import {
  SELECT_BORDER_RADIUS,
  SELECT_X_PADDING,
  SELECT_Y_PADDING,
} from './select.constants';

interface StyledSelectFormField {
  disabled: boolean;
  hasError: boolean;
  floatLabel: boolean;
  selectOpen: boolean;
}

const SelectFormField = styled(Box)<StyledSelectFormField>`
  ${(props) => {
    const { disabled, floatLabel, hasError, selectOpen, theme } = props;

    const formFieldStyles = css`
      ${baseComponentStyles};

      border-width: 1px;
      border-style: solid;
      height: 58px;
      border-radius: ${SELECT_BORDER_RADIUS}px;
      min-width: 270px;

      label {
        ${getTextStyles('bodyMd', theme)}
        color: ${theme.colors.inputLabel};
        transition: ${theme.transitions.fast};
        transition-property: top, font-variation-settings, font-size;
        top: 50%;
        transform: translateY(-50%);
      }

      ${floatLabel &&
      css`
        label {
          ${getTextStyles('bodySm', theme)}
          top: 18px;
          color: ${theme.colors.inputLabelFocus};
        }

        p {
          transform: scaleY(100%);
        }
      `}

      :focus-within {
        label {
          ${getTextStyles('bodySm', theme)}
          top: 18px;
          color: ${theme.colors.inputLabelFocus} !important;
        }

        p {
          transform: scaleY(100%);
          opacity: 1;
        }
      }
    `;

    if (disabled)
      return css`
        ${formFieldStyles};

        pointer-events: none;

        background-color: ${theme.colors.inputBackgroundDisabled};
        border-color: ${theme.colors.inputBorderDisabled};
      `;

    if (hasError) {
      return css`
        ${formFieldStyles};

        background-color: ${theme.colors.inputBackgroundError};
        border-color: ${theme.colors.inputBorderError};
      `;
    }

    return css`
      ${formFieldStyles};

      background-color: ${theme.colors.inputBackground};
      border-color: ${theme.colors.inputBorder};

      ${selectOpen &&
      css`
        border-color: ${theme.colors.inputBorderFocus};
        background-color: ${theme.colors.inputBackgroundFocus};
      `}

      :focus-within {
        border-color: ${theme.colors.inputBorderFocus};
        background-color: ${theme.colors.inputBackgroundFocus};
      }
    `;
  }}
`;

const SelectInput = styled(Box)`
  height: 100%;
  padding-bottom: ${SELECT_Y_PADDING}px;
  padding-left: ${SELECT_X_PADDING}px;
  padding-right: ${SELECT_X_PADDING}px;
  padding-top: ${SELECT_Y_PADDING * 2}px;
`;

export const Styled = { SelectFormField, SelectInput };
