import styled, { css } from 'styled-components';

import { Box } from '../box';
import { GetChipColorsReturn } from './chip.types';

export const Chip = styled(Box)<GetChipColorsReturn & { disabled?: boolean }>`
  ${(props) => {
    const { theme, backgroundColor, borderColor, labelColor, disabled } = props;

    return css`
      width: fit-content;
      padding: 4px 8px;
      border-radius: 4px;

      background-color: ${theme.colors[backgroundColor]};
      border: 1px solid ${theme.colors[borderColor]};
      color: ${theme.colors[labelColor]};

      ${disabled &&
      css`
        opacity: 0.5;
      `}
    `;
  }}
`;

export const Styled = { Chip };
