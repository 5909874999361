import React from 'react';

import { TxArgs } from '../text';

export interface StyledInputHelperBoxProps {
  disabled: boolean;
  hasError: boolean;
  floatLabel: boolean;
}

export interface FormFieldTxProps {
  error?: string;
  errorTx?: TxString;
  errorTxArgs?: TxArgs;
  helperText?: string;
  helperTx?: TxString;
  helperTxArgs?: TxArgs;
  label?: string;
  labelTx?: TxString;
  labelTxArgs?: TxArgs;
}

export interface InputBaseProps extends FormFieldTxProps {
  currentLength?: number;
  leadingElement?: JSX.Element;
  maxLength?: number;
  placeholderTx?: TxString;
  placeholderTxArgs?: TxArgs;
  trailingElement?: JSX.Element;
  hideExtraTrailingElement?: boolean;
}

export interface InputProps
  extends React.ComponentPropsWithRef<'input'>,
    InputBaseProps {}

export interface ControlledInputProps extends InputProps {
  deps?: string[];
  name: string;
}

export const datePickerTypes = ['date', 'datetime-local'] as const;

export type DatePickerType = (typeof datePickerTypes)[number];

export interface ControlledDatePickerProps extends InputProps {
  deps?: string[];

  name: string;

  type: DatePickerType;
}
