import {
  Box,
  BoxProps,
  ResponsiveBox,
  Sheet,
  VerticalButton,
} from '../../components';
import { LAYOUT_Z_INDEX } from '../../constants';
import { Styled } from './content-sidebar.styled';
import { ContentSidebarProps } from './content-sidebar.types';

export function ContentSidebarContentContainer(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box
      flex
      flexDirection="column"
      flexGrow={1}
      gap={24}
      overflow="auto"
      px={24}
      py={32}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function ContentSidebarFooterContainer(props: BoxProps) {
  const { children, ...rest } = props;

  return (
    <Box flexJustify="between" flex py={32} px={24} {...rest}>
      {children}
    </Box>
  );
}

export function ContentSidebar(props: ContentSidebarProps) {
  const { children, onClose, isOpen, width, ...rest } = props;

  return (
    <Box width="fit-content" relative>
      <ResponsiveBox
        md={
          <Styled.CloseSidebarButtonBox
            absolute
            right="calc(100% - 25px)"
            top="40%"
            zIndex={LAYOUT_Z_INDEX.contentSidebar + 1}
            isOpen={isOpen}
          >
            <VerticalButton
              onClick={onClose}
              icon="arrow-right-circle-outline"
              tx="button.close"
            />
          </Styled.CloseSidebarButtonBox>
        }
      />

      <ResponsiveBox
        height="100%"
        md={
          <Styled.ContentSidebar
            isOpen={isOpen}
            overflow="hidden"
            zIndex={LAYOUT_Z_INDEX.contentSidebarMd}
            width={isOpen ? width : 0}
            relative
          >
            <Styled.ContentSidebarContent
              absolute
              backgroundColor="contentSidebarBackground"
              height="100%"
              width={width}
              isOpen={isOpen}
              {...rest}
            >
              <Box overflow="hidden" height="100%" flexDirection="column" flex>
                {children}
              </Box>
            </Styled.ContentSidebarContent>
          </Styled.ContentSidebar>
        }
      >
        <Sheet isOpen={isOpen} onClose={onClose}>
          <Box overflow="hidden" height="100%" flexDirection="column" flex>
            {children}
          </Box>
        </Sheet>
      </ResponsiveBox>
    </Box>
  );
}
