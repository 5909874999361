import { Post } from '../../../../../models';
import { PostDto } from './posts.dto';

export function postMapper(postDto: PostDto): Post {
  return {
    activityKey: postDto.activity?.activityKey ?? null,
    activityTitle: postDto.activity?.title ?? null,
    createdAt: postDto.createdAt,
    message: postDto.message,
    postKey: postDto.postKey,
    type: postDto.type,
  };
}
