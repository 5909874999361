import React from 'react';

import { Text } from '../../text';
import { TableContext } from '../table';
import { TableRowContext } from '../table-row';
import { TABLE_SHADOW_WIDTH } from '../table.constants';
import { Styled } from './table-cell.styled';
import { TableCellProps } from './table-cell.types';

function _TableCell(
  props: TableCellProps,
  ref: React.ForwardedRef<HTMLTableCellElement>,
) {
  const {
    children,
    fixedLeft,
    fixedRight,
    hoverCell,
    tx,
    txArgs,
    text,
    ...rest
  } = props;

  const { tableContainerRef, leftShadowRef, rightShadowRef } =
    React.useContext(TableContext);

  const { to } = React.useContext(TableRowContext);

  const fixedCellBackdropRef = React.useRef<HTMLDivElement>(null);

  const positionShadow = React.useCallback(() => {
    if (!fixedCellBackdropRef.current) return;

    const rect = fixedCellBackdropRef.current.getBoundingClientRect();

    if (fixedLeft && leftShadowRef.current) {
      leftShadowRef.current.style.left = `${rect.width - TABLE_SHADOW_WIDTH}px`;
      leftShadowRef.current.style.opacity = '1';
    }

    if (fixedRight && rightShadowRef.current) {
      rightShadowRef.current.style.right = `${
        rect.width - TABLE_SHADOW_WIDTH
      }px`;
      rightShadowRef.current.style.opacity = '1';
    }
  }, [
    fixedCellBackdropRef,
    fixedLeft,
    fixedRight,
    leftShadowRef,
    rightShadowRef,
  ]);

  React.useEffect(() => {
    positionShadow();

    const tableContainer = tableContainerRef.current;

    if (!tableContainer) return;

    const observer = new ResizeObserver(positionShadow);

    observer.observe(tableContainer);

    return () => {
      observer.disconnect();
    };
  }, [positionShadow, tableContainerRef]);

  const content = children || (
    <Text variant="bodySm" tx={tx} text={text} txArgs={txArgs} as="span" />
  );

  return (
    <Styled.TableCell
      fixedLeft={fixedLeft}
      fixedRight={fixedRight}
      ref={ref}
      {...rest}
    >
      {(fixedLeft || fixedRight) && (
        <div
          ref={fixedCellBackdropRef}
          style={{ inset: 0, position: 'absolute', pointerEvents: 'none' }}
        ></div>
      )}

      {to ? (
        <Styled.TableCellLink to={to}>
          <Styled.TableCellContent
            className="table-cell-content"
            flex
            flexAlign="center"
            height="100%"
            hoverCell={hoverCell}
            px={16}
          >
            {content}
          </Styled.TableCellContent>
        </Styled.TableCellLink>
      ) : (
        <Styled.TableCellContent
          className="table-cell-content"
          flex
          flexAlign="center"
          hoverCell={hoverCell}
          px={16}
        >
          {content}
        </Styled.TableCellContent>
      )}
    </Styled.TableCell>
  );
}

export const TableCell = React.forwardRef(_TableCell);
