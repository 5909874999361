import { TextVariant } from '@orbiapp/theme';
import { DefaultTheme, css } from 'styled-components';

export function getFontVariationSettings(variant: TextVariant) {
  switch (variant) {
    case 'displayXl':
    case 'displayLg':
    case 'displayMd':
    case 'displaySm':
    case 'displayXs':
    case 'displayXxs':
      return css`
        font-variation-settings: 'opsz' 58, 'GRAD' 62, 'slnt' 0, 'XTRA' 546,
          'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 668, 'YTAS' 750, 'YTDE' -203,
          'YTFI' 738;
      `;

    case 'titleLg':
    case 'titleMd':
    case 'titleSm':
    case 'titleXs':
      return css`
        font-variation-settings: 'opsz' 25, 'GRAD' 0, 'slnt' 0, 'XTRA' 468,
          'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203,
          'YTFI' 738;
      `;

    case 'bodyLg':
    case 'bodyMd':
    case 'bodySm':
    case 'bodyXs':
    case 'bodyLgBold':
    case 'bodyMdBold':
    case 'bodySmBold':
    case 'bodyLgItalic':
    case 'bodyMdItalic':
    case 'bodySmItalic':
      return css`
        font-variation-settings: 'opsz' 20, 'GRAD' 0, 'slnt' 0, 'XTRA' 468,
          'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203,
          'YTFI' 738;
      `;

    case 'buttonMd':
    case 'buttonSm':
      return css`
        font-variation-settings: 'opsz' 20, 'GRAD' 29, 'slnt' 0, 'XTRA' 468,
          'XOPQ' 96, 'YOPQ' 79, 'YTLC' 514, 'YTUC' 712, 'YTAS' 750, 'YTDE' -203,
          'YTFI' 738;
      `;
  }
}

export function getTextStyles(variant: TextVariant, theme: DefaultTheme) {
  const fontStyles = theme.textVariants[variant];
  const fontVariationSettings = getFontVariationSettings(variant);

  return css`
    ${fontVariationSettings};

    font-size: ${fontStyles.fontSize}px;
    font-family: ${fontStyles.fontFamily};
    letter-spacing: ${fontStyles.letterSpacing}px;
    line-height: ${fontStyles.lineHeight}px;
  `;
}
