import { Box } from '../box';
import { IconButton } from '../icon-button';
import { Text } from '../text';
import { TinySelectOptionProps } from '../tiny-select';
import { PaginatorProps } from './paginator.types';

export const paginatorOptions: TinySelectOptionProps<number>[] = [
  { value: 10, text: '10' },
  { value: 25, text: '25' },
  { value: 50, text: '50' },
  { value: 100, text: '100' },
];

export function Paginator(props: PaginatorProps) {
  const { currentPage, hasNextPage, hasPrevPage, onPaginate } = props;

  const handlePrevPageClick = () => {
    onPaginate(currentPage - 1);
  };

  const handleNextPageClick = () => {
    onPaginate(currentPage + 1);
  };

  return (
    <Box flexAlign="center" gap={24} flex>
      <IconButton
        disabled={!hasPrevPage}
        icon="chevron-left"
        iconSize={20}
        onClick={handlePrevPageClick}
      />

      <Text text={currentPage + 1} variant="bodySm" />

      <IconButton
        disabled={!hasNextPage}
        icon="chevron-right"
        iconSize={20}
        onClick={handleNextPageClick}
      />
    </Box>
  );
}
