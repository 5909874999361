import { Box } from '@orbiapp/components';
import styled from 'styled-components';

const CheckboxGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: fit-content;
  gap: 16px;
`;

export const Styled = { CheckboxGrid };
