import {
  Alert,
  AlertVariant,
  Snackbar,
  Time,
  useAlertContext,
} from '@orbiapp/components';

import {
  GlobalUiStateSelector,
  globalUiStateActions,
  useDispatch,
  useSelector,
} from '../../store';

function getAlertTitleTx(alertVariant: AlertVariant): TxString {
  switch (alertVariant) {
    case 'error':
      return 'action.general.title.error';
    case 'info':
      return 'action.general.title.info';
    case 'success':
      return 'action.general.title.success';
    case 'warning':
      return 'action.general.title.warning';
  }
}

export function GlobalSnackbar() {
  const dispatch = useDispatch();

  const globalAlert = useSelector(GlobalUiStateSelector.selectAlert);

  const alertContext = useAlertContext();

  const alert = alertContext.alert ?? globalAlert;

  if (!alert) {
    return null;
  }

  const clearAlert = () => {
    dispatch(globalUiStateActions.clearAlert());
    alertContext.clearAlert();
  };

  return (
    <Snackbar
      placement="right-start"
      autoHideDuration={alert?.autoHideDuration ?? 5 * Time.Second}
      onClose={clearAlert}
      zIndex={1000}
    >
      <Alert
        subtitle={alert?.subtitle}
        subtitleTx={alert?.subtitleTx}
        subtitleTxArgs={alert?.subtitleTxArgs}
        titleTx={getAlertTitleTx(alert.variant)}
        variant={alert.variant}
      />
    </Snackbar>
  );
}
