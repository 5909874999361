import styled, { css } from 'styled-components';

import { Box } from '../../components/box';
import { INPUT_X_PADDING } from '../../constants';
import { StyledInputIconBoxProps } from './input-trailing-element-context.types';

const IconInputBoxProps = styled(Box)<StyledInputIconBoxProps>`
  ${(props) => {
    const { align, disabled, hasError, theme } = props;

    const iconBoxStyles = css`
      width: fit-content;
      top: calc(50% - 10px);

      ${align === 'left'
        ? css`
            left: ${INPUT_X_PADDING}px;
          `
        : css`
            right: ${INPUT_X_PADDING}px;
          `};
    `;

    if (disabled) {
      return css`
        ${iconBoxStyles};
        color: ${theme.colors.inputIconDisabled};
      `;
    }

    if (hasError) {
      return css`
        ${iconBoxStyles};
        color: ${theme.colors.inputIconError};
      `;
    }

    return css`
      ${iconBoxStyles};
      color: ${theme.colors.inputIcon};
    `;
  }}
`;

export const Styled = { IconInputBoxProps };
