import { initialPostsState } from './posts.initial';
import { PostsState } from './posts.types';

const reset = (state: PostsState) => {
  Object.assign(state, initialPostsState);
};

const clearPosts = (state: PostsState) => {
  state.posts = initialPostsState.posts;
};

const clearActivityPosts = (state: PostsState) => {
  state.activityPosts = initialPostsState.activityPosts;
};

const clearDepartmentPosts = (state: PostsState) => {
  state.departmentPosts = initialPostsState.departmentPosts;
};

export const reducers = {
  reset,

  clearActivityPosts,
  clearDepartmentPosts,
  clearPosts,
};
