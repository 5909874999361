import { Color, TextVariant } from '@orbiapp/theme';

import { Box, BoxProps } from '../box';
import { Chip } from '../chip';
import { Icon, IconName } from '../icon';
import { Spinner } from '../spinner';
import { Text, TxArgs } from '../text';
import { Tooltip } from '../tooltip';
import { Styled } from './selection-card.styled';

interface SelectionCardListItemProps {
  label?: string;
  labelTx?: TxString;
  labelTxArgs?: TxArgs;

  tooltip?: string;
  tooltipTx?: TxString;
  tooltipTxArgs?: TxArgs;

  icon?: IconName;
  iconColor?: Color;
}

function SelectionCardListItem(props: SelectionCardListItemProps) {
  const {
    label,
    labelTx,
    labelTxArgs,

    tooltip,
    tooltipTx,
    tooltipTxArgs,

    iconColor = 'selectionCardIcon',
    icon = 'check-circle-solid',
  } = props;

  const selectionCardContent = (
    <Box flex gap={8} flexAlign="center">
      <Icon color={iconColor} name={icon} />

      <Text
        variant="bodyMd"
        lineHeight={1}
        text={label}
        tx={labelTx}
        txArgs={labelTxArgs}
      />
    </Box>
  );

  if (!tooltip && !tooltipTx) {
    return selectionCardContent;
  }

  return (
    <Box flexAlign="center" flex flexJustify="between" gap={16}>
      {selectionCardContent}

      <Tooltip
        placement="bottom"
        titleTx={tooltipTx}
        title={tooltip}
        titleTxArgs={tooltipTxArgs}
      >
        <Icon
          color="selectionCardTooltipIcon"
          name="question-mark-circle-outline"
        />
      </Tooltip>
    </Box>
  );
}

function SelectionCardList(props: BoxProps) {
  return <Box flex flexDirection="column" {...props} />;
}

function renderSelectionCardListItem(
  listItem: SelectionCardListItemProps,
  index: number,
) {
  return <SelectionCardListItem key={index} {...listItem} />;
}

interface SelectionCardProps extends BoxProps {
  chipText?: string;
  chipTx?: TxString;
  chipTxArgs?: TxArgs;

  disabled?: boolean;
  isLoading?: boolean;
  listItems?: SelectionCardListItemProps[];
  listGap?: number;
  onClick: () => void;

  subtitleVariant?: TextVariant;
  subtitle?: string;
  subtitleTx?: TxString;
  subtitleTxArgs?: TxArgs;

  title?: string;
  titleTx: TxString;
  titleTxArgs?: TxArgs;
  titleVariant?: TextVariant;
  toggled: boolean;
}

export function SelectionCard(props: SelectionCardProps) {
  const {
    chipTx,
    chipText,
    chipTxArgs,

    disabled,
    isLoading,
    listItems,
    listGap = 16,
    onClick,

    subtitle,
    subtitleTx,
    subtitleTxArgs,
    subtitleVariant = 'bodyMd',

    title,
    titleTx,
    titleTxArgs,
    titleVariant = 'bodyMdBold',
    toggled,
    ...rest
  } = props;

  return (
    <Styled.SelectionCard
      aria-disabled={disabled}
      disabled={disabled || isLoading}
      flex
      flexDirection="column"
      onClick={onClick}
      toggled={toggled}
      {...rest}
    >
      {isLoading && (
        <Box
          flex
          flexJustify="center"
          flexAlign="center"
          zIndex={5}
          absolute
          inset
        >
          <Spinner />
        </Box>
      )}

      <Box flex flexJustify="between" gap={4} flexAlign="start">
        <Box flex flexDirection="column" gap={4}>
          <Text
            color="selectionCardTitle"
            text={title}
            tx={titleTx}
            variant={titleVariant}
          />

          {(subtitleTx || subtitle) && (
            <Text
              color="selectionCardLabel"
              maxWidth="32ch"
              text={subtitle}
              tx={subtitleTx}
              txArgs={subtitleTxArgs}
              variant={subtitleVariant}
            />
          )}
        </Box>

        {(chipTx || chipText) && (
          <Chip
            text={chipText}
            tx={chipTx}
            txArgs={chipTxArgs}
            variant="secondary"
          />
        )}
      </Box>

      {listItems && (
        <SelectionCardList gap={listGap}>
          {listItems.map(renderSelectionCardListItem)}
        </SelectionCardList>
      )}
    </Styled.SelectionCard>
  );
}
