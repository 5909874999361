import Joi from 'joi';

import { DepartmentPostSchema } from './department-post.schema';

export const CreateDepartmentPostValidation = Joi.object({
  audiences: DepartmentPostSchema.audiences,
  message: DepartmentPostSchema.message,
});

export const UpdateDepartmentPostValidation = Joi.object({
  departmentPostKey: DepartmentPostSchema.departmentPostKey,
  message: DepartmentPostSchema.message,
});
