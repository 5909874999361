import React from 'react';
import ReactDOM from 'react-dom';

import { Button } from '../button';
import {
  Modal,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
} from '../modal';
import { Text, TextProps } from '../text';
import { ConfirmProps } from './confirm.types';

export function useConfirm<T = any>() {
  const [isOpen, setIsOpen] = React.useState(false);

  const [confirmValue, setConfirmValue] = React.useState<T | null>(null);

  const openConfirm = (confirmValue?: T) => {
    if (confirmValue) {
      setConfirmValue(confirmValue);
    }

    setIsOpen(true);
  };

  const closeConfirm = () => {
    setConfirmValue(null);
    setIsOpen(false);
  };

  return {
    confirmValue,
    closeConfirm,
    isOpen,
    openConfirm,
  };
}

export function ConfirmText(props: TextProps) {
  return <Text color="confirmMessage" variant="bodyMd" {...props} />;
}

export function Confirm(props: ConfirmProps) {
  const {
    children,
    cancelText,
    cancelTx,
    cancelTxArgs,
    confirmText,
    confirmTx,
    confirmTxArgs,
    isLoading,
    isOpen,
    message,
    messageTx,
    messageTxArgs,
    onCancel,
    onConfirm,
    title,
    titleTx,
    titleTxArgs,
    confirmButttonVariant = 'destructive',
  } = props;

  return ReactDOM.createPortal(
    <Modal zIndex={1010} isOpen={isOpen} onClose={onCancel}>
      <ModalContentContainer>
        <ModalHeaderContainer>
          <Text
            color="confirmTitle"
            text={title}
            tx={titleTx}
            txArgs={titleTxArgs}
            variant="titleMd"
          />
        </ModalHeaderContainer>

        <ModalBodyContainer>
          {children || (
            <ConfirmText text={message} tx={messageTx} txArgs={messageTxArgs} />
          )}
        </ModalBodyContainer>

        <ModalFooterContainer>
          <Button
            disabled={isLoading}
            onClick={onCancel}
            text={cancelText}
            tx={cancelTx}
            txArgs={cancelTxArgs}
            variant="tertiary"
          />

          <Button
            isLoading={isLoading}
            onClick={onConfirm}
            text={confirmText}
            tx={confirmTx}
            txArgs={confirmTxArgs}
            variant={confirmButttonVariant}
          />
        </ModalFooterContainer>
      </ModalContentContainer>
    </Modal>,
    document.body,
  );
}
