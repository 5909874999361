import { Base64File, Coordinates, ImageData } from '@orbiapp/components';

import { PartialOrgNode } from '../org-node';

export const segmentationType = ['year', 'semester'] as const;

export type SegmentationType = (typeof segmentationType)[number];

export interface DepartmentLocation {
  address: string;
  coordinates: Coordinates;
  placeId: string;
}

export interface Department {
  departmentKey: string;

  followerCount: number;

  name: string;
  schoolName: string;
  segmentationType: SegmentationType;

  coverImage: ImageData | null;
  logo: ImageData | null;

  enableTags: boolean;
  isCareerAdmin: boolean;

  about: string | null;
  email: string | null;
  phone: string | null;

  orgNodesBranch: PartialOrgNode[];

  locations: DepartmentLocation[] | null;

  facebookUrl: string | null;
  instagramUrl: string | null;
  twitterUrl: string | null;
  websiteUrl: string | null;
  youtubeUrl: string | null;

  referredAt: number | null;
}

export interface DepartmentForm {
  about: string | null;
  coverImage: Base64File | null;
  email: string | null;
  facebookUrl: string | null;
  instagramUrl: string | null;
  logo: Base64File | null;
  name: string;
  phone: string | null;
  twitterUrl: string | null;
  websiteUrl: string | null;
  youtubeUrl: string | null;
}

export interface UpdateDepartmentResponse {
  logo: ImageData;
  coverImage: ImageData;
}

export interface PartialDepartment {
  departmentKey: string;

  name: string;
  logo: ImageData | null;
  orgNodeBranch: PartialOrgNode[];
}
