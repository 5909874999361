import { Pagination } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ActivityPost,
  CreateActivityPost,
  CreateDepartmentPost,
  DepartmentPost,
  Post,
  PostOrderByKey,
  UpdateActivityPost,
  UpdateDepartmentPost,
} from '../../models';
import { OrbiApi, getPageAndNextPage, v1, v2 } from '../../services';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import {
  activityPostsAdapter,
  departmentPostsAdapter,
  postsAdapter,
} from './posts.adapter';

export const getPostsThunk = createAsyncThunk<
  Post[],
  Pagination<PostOrderByKey>,
  ThunkApiConfig
>('posts/get-all', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const [currentPage, nextPage] = await getPageAndNextPage(
    postsAdapter.getSelectors().selectAll(state.posts.posts.data),
    v2.posts.getPosts,
    { pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const createActivityPostThunk = createAsyncThunk<
  { activityPostKey: string; activityTitle: string | null },
  CreateActivityPost,
  ThunkApiConfig
>('posts/create-activity-post', async (createActivityPost, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.posts.createActivityPost,
    createActivityPost,
  );

  if (res.kind === 'ok') {
    const state = thunkAPI.getState();

    thunkAPI.dispatch(setAlert('create-activity-post:success'));

    const activityTitle =
      state.activity.activityRecords.data?.find(
        (activityRecord) =>
          activityRecord.activityKey === createActivityPost.activityKey,
      )?.activityTitle ??
      state.activity.activity.data?.title ??
      null;

    return { activityTitle, activityPostKey: res.data };
  }

  thunkAPI.dispatch(setAlert('create-activity-post:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateActivityPostThunk = createAsyncThunk<
  void,
  UpdateActivityPost,
  ThunkApiConfig
>('posts/update-activity-post', async (updateActivityPost, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.posts.updateActivityPost,
    updateActivityPost,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-activity-post:success'));

    return;
  }

  thunkAPI.dispatch(setAlert('update-activity-post:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getActivityPostsThunk = createAsyncThunk<
  ActivityPost[],
  Pagination,
  ThunkApiConfig
>('posts/get-activity-posts', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();
  const activityKey = state.activity.activity.data?.activityKey;
  if (!activityKey) return thunkAPI.rejectWithValue({ kind: 'bad-request' });

  const [currentPage, nextPage] = await getPageAndNextPage(
    activityPostsAdapter
      .getSelectors()
      .selectAll(state.posts.activityPosts.data),
    v1.activities.posts.getActivityPosts,
    { activityKey, pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const createDepartmentPostThunk = createAsyncThunk<
  string,
  CreateDepartmentPost,
  ThunkApiConfig
>('posts/create-department-post', async (createDepartmentPost, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.department.posts.createDepartmentPost,
    createDepartmentPost,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('create-department-post:success'));

    return res.data;
  }

  thunkAPI.dispatch(setAlert('create-department-post:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateDepartmentPostThunk = createAsyncThunk<
  void,
  UpdateDepartmentPost,
  ThunkApiConfig
>('posts/update-department-post', async (updateDepartmentPost, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.department.posts.updateDepartmentPost,
    updateDepartmentPost,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-department-post:success'));

    return;
  }

  thunkAPI.dispatch(setAlert('update-department-post:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getDepartmentPostsThunk = createAsyncThunk<
  DepartmentPost[],
  Pagination,
  ThunkApiConfig
>('posts/get-department-posts', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const [currentPage, nextPage] = await getPageAndNextPage(
    departmentPostsAdapter
      .getSelectors()
      .selectAll(state.posts.departmentPosts.data),
    v1.department.posts.getDepartmentPosts,
    { pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});
