import Joi from 'joi';

import { audienceTypes } from '../shared';
import { DEPARTMENT_POST_MESSAGE_MAX_LENGTH } from './department-post.constants';
import { DepartmentPostErrors } from './department-post.errors';

export namespace DepartmentPostSchema {
  export const departmentPostKey = Joi.string().required();

  export const audiences = Joi.array()
    .items(Joi.string().valid(...audienceTypes))
    .allow(null)
    .required();

  export const message = Joi.string()
    .max(DEPARTMENT_POST_MESSAGE_MAX_LENGTH)
    .required()
    .messages(DepartmentPostErrors.message);
}
