import { AnchoredMenuState, Time, useAnchoredMenu } from '@orbiapp/components';
import React from 'react';

interface ProfileMenuContextType extends AnchoredMenuState {
  anchorRef: React.RefObject<HTMLDivElement>;
  clickOutsideRef: React.RefObject<HTMLDivElement>;
  menuClickOutsideRef: React.RefObject<HTMLDivElement>;
}

export const ProfileMenuContext = React.createContext<ProfileMenuContextType>({
  anchorRef: { current: null },
  clickOutsideRef: { current: null },
  closeMenu: () => {},
  isOpen: false,
  menuClickOutsideRef: { current: null },
  menuRef: { current: null },
  openMenu: () => {},
  toggleMenu: () => {},
});

export function ProfileMenuProvider(props: React.PropsWithChildren) {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const clickOutsideRef = React.useRef<HTMLDivElement>(null);
  const menuClickOutsideRef = React.useRef<HTMLDivElement>(null);

  const profileMenuState = useAnchoredMenu({
    anchorRef,
    placement: 'bottom-end',
    autoCloseDuration: 5 * Time.Second,
    clickOutsideRef: [menuClickOutsideRef, anchorRef],
  });

  return (
    <ProfileMenuContext.Provider
      value={{
        ...profileMenuState,
        anchorRef,
        clickOutsideRef,
        menuClickOutsideRef,
      }}
      {...props}
    />
  );
}
